import { toast } from "react-toastify";
import { CustomToast } from "../components/toast-component";
import { COUNTRIES } from "./countries";
import axios from "axios";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

const USER_DATA = "userData";

export function isUserLoggedIn() {
  return localStorage.getItem(USER_DATA);
}

export function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

export function userLoggout() {
  localStorage.clear();
  return window.location.replace("/login");
}

export function LSUserData() {
  return JSON.parse(localStorage.getItem(USER_DATA));
}

export function updateLSOutlet(outlet) {
  let userData = JSON.parse(localStorage.getItem(USER_DATA));
  userData = { ...userData, retailOutlets: [{ ...outlet }] };
  localStorage.setItem(USER_DATA, JSON.stringify(userData));
}

export const formatMoney = (value) =>
  new Intl.NumberFormat("en-US", {}).format(value);

export function errorHandler(error) {
  const res = { message: "" };
  console.log("received error", error);
  console.log(Object.keys(error));
  const { response } = error;
  if (!navigator.onLine) {
    res.message = "You are offline. Please check your internet connection.";
    return res;
  }
  if (response?.data) {
    if (Array.isArray(response.data.errors)) {
      res.message = response.data.errors.map(
        (err, key) =>
          `${err.error || ""}${
            key < response.data.errors.length - 1 ? ", " : "."
          } `
      );
    }
    if (response?.data?.customCode) res.customCode = response.data.customCode;
    if (response.data?.data?.customCode) {
      res.message =
        response?.data?.data?.message ||
        customErrorCodes(response.data.data.customCode);
      res.customCode = response.data.data.customCode;
    } else if (response?.data?.res && typeof response?.data?.res === "string")
      res.message = response.data.res;
    else if (response?.data && typeof response?.data === "string")
      res.message = response.data;
    else if (
      response?.data?.message &&
      typeof response?.data?.message === "string"
    )
      res.message = response.data.message;
    else if (
      response?.data?.error?.data?.message &&
      typeof response?.data?.error?.data?.message === "string"
    )
      res.message = response.data.error.data.message;
    else if (
      response?.data?.data?.message &&
      typeof response?.data?.data?.message === "string"
    )
      res.message = response.data.data.message;
  } else if (response?.status) res.statusCode = response.status;
  else if (!res.message) {
    res.message = "Cannot complete action, please try again later!";
  }
  console.log({ response });
  console.log({ res });
  return res;
}

export function renderErrorToast(
  title,
  message,
  toastId,
  children,
  timer = 3000
) {
  toast(<CustomToast title={title} bodyText={message} children={children} />, {
    toastId: toastId || message,
    position: "bottom-right",
    autoClose: timer,
  });
}

function customErrorCodes(errorCode) {
  let errorMessage = "";
  switch (errorCode) {
    case 1105:
      errorMessage =
        "The email already exists - The email is already associated with an account. Please use a different email to signup";
      break;
    case 1012:
      errorMessage =
        "KYC Required - Before you proceed with your application, we need to verify your identity.";
      break;
    default:
      errorMessage = "Ooops! Something went wrong. Please try again later.";
  }
  return errorMessage;
}

export function getCountryDetails(countryCode) {
  return COUNTRIES.find((el) => el.code === countryCode);
}

export function getRandomAlphanumericNumbers() {
  return Math.random().toString(24).slice(6);
}

export function isAParentOutlet() {
  const {
    retailOutlets: [retailOutlet],
  } = LSUserData();
  if (!retailOutlet?.parentOutletId) return true;
  return false;
}

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function capitalizeStatusText(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const processOrderCalculations = (orders, transaction) => {
  let items = [];
  let discounts = 0;
  let total = 0;
  let shippingFees = 0;
  let taxes = 0;
  let subtotal = 0;
  let availableOrders = orders;
  if (transaction.status !== "cancelled")
    availableOrders = availableOrders.filter((el) => el.status !== "cancelled");
  items = availableOrders
    .flatMap((el) => el.items)
    .map((el) => ({
      ...el,
      price: el.price + (el.promoDiscount || 0),
      count: el.quantity,
      discount: el.discount,
      total: el.price * el.quantity - el.discount,
      currency: transaction?.currency || {},
    }));
  discounts = availableOrders.reduce(
    (acc, curr) => acc + curr.discounts || 0,
    0
  );
  total = transaction?.amount || 0;
  shippingFees = availableOrders.reduce(
    (acc, curr) => acc + curr.shippingCosts || 0,
    0
  );
  taxes = availableOrders.reduce((acc, curr) => acc + curr.taxes || 0, 0);
  subtotal = transaction?.amount || 0;
  if (subtotal) subtotal = subtotal - taxes - shippingFees;
  return { items, discounts, taxes, total, shippingFees, subtotal };
};

export const handlePrintOrder = async (order, setLoading, setOrder) => {
  setLoading(true);
  const { apiKey } = LSUserData() || {};
  try {
    if (order.draftOrderNumber) {
      const subtotal = order.items.reduce((acc, curr) => {
        return acc + curr.price * curr.quantity;
      }, 0);
      const total = subtotal;
      const poOrders = order.items.map((el) => ({
        ...el,
        count: el.quantity,
        currency: order.currency,
      }));
      setOrder({
        orderReference: order.draftOrderNumber,
        order,
        poOrders,
        totalCalculation: {
          subtotal,
          discount: 0,
          total,
          amountDue: total,
        },
      });
      setLoading(false);
    }
    if (!order.draftOrderNumber) {
      const {
        data: {
          data: { orders },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_APP_URL}/api/v3/procurement/transactions/${order._id}`,
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      const processedCalculations = processOrderCalculations(orders, order);
      const discounts = processedCalculations.discounts;
      const items = processedCalculations.items;
      const subtotal = processedCalculations.subtotal;
      const taxes = processedCalculations.taxes;
      const shippingFees = processedCalculations.shippingFees;
      const total = processedCalculations.total;
      setOrder({
        orderReference: order.reference,
        order: order,
        poOrders: items,
        totalCalculation: {
          subtotal,
          discount: discounts,
          total: total,
          amountDue: total,
          taxes,
          shippingFees,
        },
      });
      setLoading(false);
    }
  } catch (error) {
    const { message } = errorHandler(error);
    renderErrorToast("Error printing order", message, "printing-order-err");
  }
};

export function scrollBackToTop() {
  return window.scrollTo({ top: 0 });
}

export function numberHasString(value) {
  const regex = /[a-zA-Z]/;
  return regex.test(value); // Returns true if there is at least one letter
}

export function validatePhoneNumberWithGoogle(value, countryCode) {
  try {
    const number = phoneUtil.parseAndKeepRawInput(value, countryCode);
    return {
      isNumberValid: phoneUtil.isValidNumber(number),
      formattedNumber: phoneUtil.format(number, PhoneNumberFormat.E164),
    };
  } catch (error) {
    return { isNumberValid: false, formattedNumber: null };
  }
}
