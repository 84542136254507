import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { Register } from "./pages/register";
import { Login } from "./pages/login";
import { Console } from "./pages/console";
import { Layout } from "./components/layout";
import { Onboarding } from "./pages/onboarding";
import { Orders } from "./pages/orders";
import { CreateOrders } from "./pages/create-orders";
import { useEffect, useState } from "react";
import { MobileView } from "./components/mobile-view";
import { isUserLoggedIn } from "./utils/functions";
import { Invoices } from "./pages/invoices";
import { AccountStatement } from "./pages/account-statement";
import refreshOutlet from "./utils/refreshOutlet";
import { OrderView } from "./components/order-view";
import { Catalog } from "./pages/catalog";
import { NewRegistration } from "./pages/register-new";
import { ConfirmOTP } from "./pages/confirm-otp";

function Router() {
  const [isMobile] = useState(window.innerWidth <= 1024);
  const { pathname } = window.location;
  if (pathname === "/") window.location.replace("/console");
  const authRoutes = ["/login", "/register", "/confirm"];
  if (!isUserLoggedIn() && !authRoutes.includes(pathname)) {
    window.location.replace("/login");
  }
  if (isUserLoggedIn() && authRoutes.includes(pathname))
    window.location.replace("/console");

  const handleGetOutlet = async () => {
    if (!isUserLoggedIn()) return;
    refreshOutlet();
  };

  useEffect(() => {
    handleGetOutlet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobile ? (
    <MobileView />
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path='/register' element={<NewRegistration />} />
        {/* <Route path='/register' element={<Register />} /> */}
        <Route path='/login' element={<Login />} />
        <Route path='/confirm' element={<ConfirmOTP />} />
        <Route path='/' element={<Layout />}>
          <Route path='/console' element={<Console />} />
          <Route path='/console/reports' element={<Console />} />
          <Route path='/console/invoices' element={<Invoices />} />
          <Route path='/console/orders' element={<Orders />} />
          <Route path='/console/catalog' element={<Catalog />} />
          <Route path='/console/suppliers' element={<Console />} />
          <Route
            path='/console/account-statement'
            element={<AccountStatement />}
          />
        </Route>
        <Route path='/onboarding' element={<Onboarding />} />
        <Route path='/console/order/:orderId' element={<OrderView />} />
        <Route path='/create-order' element={<CreateOrders />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
