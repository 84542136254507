import { useEffect, useRef, useState } from "react";
import { BuildingIcon } from "../assets/register.building";
import { UserCircleIcon } from "../assets/user.circle.icon";
import { useFormik } from "formik";
import {
  RetailerRegisterScreenOneSchema,
  PartnerRegisterScreenOneSchema,
  PartnerRegisterScreenTwoSchema,
} from "../utils/schema";
import { AuthWrapper } from "../components/auth-wrapper";
import { CustomSelect } from "../components/custom-select";
import axios from "axios";
import {
  errorHandler,
  numberHasString,
  renderErrorToast,
  validateEmail,
  validatePhoneNumberWithGoogle,
} from "../utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../components/loader";
import { MessageIcon } from "../assets/message.icon";

const PARTNER = "PARTNER";
const RETAILER = "RETAILER";

export const NewRegistration = () => {
  const { state: existingValues } = useLocation();
  const [loading, setLoading] = useState(false);
  const [formScreen, setFormScreen] = useState(
    existingValues?.selectedUserType ? 1 : 0
  );
  const [selectedUserType, setSelectedUserType] = useState(
    existingValues?.selectedUserType || ""
  ); // options are *partner* and *RETAILER*
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [countryAddress, setCountryAddress] = useState("");

  const isDev = process.env.NODE_ENV !== "production";

  const handleSubmitForm = (values) => {
    if (selectedUserType === PARTNER) return handlePartnerFormSubmit(values);
    else if (selectedUserType === RETAILER)
      return handleRetailersFormSubmit(values);
  };

  const formik = useFormik({
    initialValues: {
      outletBusinessName: "",
      outletPhoneNumber: "",
      outletEmail: "",
      storeAddress: "",
      countryCode: "",
      outletTypeId: "",
      firstName: "",
      lastName: "",
      action: "register",
      contactPosition: "",
      contactEmail: "",
      contactPhoneNumber: "",
      ...existingValues,
    },
    validationSchema:
      selectedUserType === PARTNER
        ? formScreen === 1
          ? PartnerRegisterScreenOneSchema
          : PartnerRegisterScreenTwoSchema
        : selectedUserType === RETAILER
        ? RetailerRegisterScreenOneSchema
        : null,
    onSubmit: handleSubmitForm,
  });

  const handleVerifyEmailFormat = (email) => {
    // verify email address format
    const isEmailValid = validateEmail(email.toLowerCase());
    if (!isEmailValid)
      throw renderErrorToast(
        "Invalid Email",
        "Email Format is invalid",
        "invalid-email-address-err-2"
      );
  };

  const handleVerifyPhoneNumber = (phoneNumber, countryCode) => {
    // verify phone number
    const isValidPhoneNumber = !numberHasString(phoneNumber);
    const { isNumberValid: numberIsValid, formattedNumber } =
      validatePhoneNumberWithGoogle(phoneNumber, countryCode);
    if (!isValidPhoneNumber || !countryCode || !numberIsValid)
      throw renderErrorToast(
        "Invalid Phone Number/Country",
        "The number provided does not match with address. Please, check your phone number.",
        "invalid-phone-number-err-2"
      );
    formik.setFieldValue("formattedPhoneNumber", formattedNumber);
    return { formattedPhoneNumber: formattedNumber };
  };

  const handleRetailersFormSubmit = (values) => {
    if (formScreen === 1) {
      if (values.formattedAddress !== values.storeAddress) {
        return formik.setFieldError(
          "storeAddress",
          "Please enter a valid address"
        );
      }
      if (values.countryCode === "GB") {
        if (!values.postcode)
          return renderErrorToast(
            "Postal Code Error",
            "Please enter a valid address with postcode",
            "error-add-1"
          );
        if (values.postcode.length < 6)
          return renderErrorToast(
            "Postal Code Error",
            "Please enter a valid postcode",
            "error-add-2"
          );
      }
      setFormScreen(2);
    }
    if (formScreen === 2) {
      handleVerifyEmailFormat(values.contactEmail);
      const { formattedPhoneNumber } = handleVerifyPhoneNumber(
        values.contactPhoneNumber,
        values.countryCode
      );
      checkIfEmailExists({ ...formik.values, formattedPhoneNumber });
    }
  };

  const checkIfEmailExists = async (retailer) => {
    setLoading(true);
    try {
      const {
        data: {
          data: { data },
        },
      } = await axios.get(
        `${
          process.env.REACT_APP_FIREBASE_URL
        }/shop/v4/getPhoneStatus?email=${formik.values.contactEmail.toLowerCase()}`
      );
      if (data) {
        setLoading(false);
        return navigate("/confirm", {
          state: { ...formik.values, action: "login" },
        });
      }
      navigate("/confirm", { state: { ...formik.values, ...retailer } });
    } catch (error) {
      const { message } = errorHandler(error);
      if (message.toLowerCase() === "outlet not found")
        return navigate("/confirm", {
          state: { ...formik.values, ...retailer },
        });
      setLoading(false);
      renderErrorToast(
        "Registration Error",
        message || "Error finding email duplicate",
        "user-validity-err"
      );
    }
  };

  const handlePartnerFormSubmit = (values) => {
    if (formScreen === 1) {
      setCountryAddress("");
      inputRef.current = null;
      return setFormScreen(2);
    }
    handleVerifyEmailFormat(values.contactEmail);
    const { formattedPhoneNumber } = handleVerifyPhoneNumber(
      values.contactPhoneNumber,
      values.countryCode
    );
    handleCreatePartner({ ...values, formattedPhoneNumber });
  };

  const handleCreatePartner = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_AWS_API_URL_V4}/hubspot-proxy`,
        {
          path: `crm/v3/objects/contacts/${values.contactEmail}`,
          method: "GET",
          params: {
            idProperty: "email",
          },
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_HUBSPOT_API_SERVER_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      // TODO: What happens if there's a duplicate contact
      console.log({ res });
      setLoading(false);
      setFormScreen(3);
    } catch (error) {
      if (error?.response?.status === 404) return handleCreateContact(values);
      setLoading(false);
      return renderErrorToast(
        "An error occurred!",
        "There was an error registering your company. Please try again later",
        "error-partner-1"
      );
    }
  };

  const handleCreateContact = async (values) => {
    console.log({ values }, "in contact");
    try {
      const {
        data: { id: contactId },
      } = await axios.post(
        `${process.env.REACT_APP_AWS_API_URL_V4}/hubspot-proxy`,
        {
          path: `crm/v3/objects/contacts`,
          method: "POST",
          params: {
            properties: {
              email: values.contactEmail,
              firstname: `${values.firstName}${isDev ? "- TEST" : ""}`,
              lastname: `${values.lastName}${isDev ? "- TEST" : ""}`,
              phone: `${values.formattedPhoneNumber}`,
              company: values.outletBusinessName,
              jobtitle: values.contactPosition,
            },
          },
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_HUBSPOT_API_SERVER_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      await handleCreateCompany(values, contactId);
    } catch (error) {
      renderErrorToast(
        "An error occurred!",
        "Something went wrong while registering your company. Please try again later or contact support",
        "error-creating-company-1"
      );
    }
  };

  const handleCreateCompany = async (values, contactId) => {
    console.log("values in create company", values);
    try {
      await axios.post(
        `${process.env.REACT_APP_AWS_API_URL_V4}/hubspot-proxy`,
        {
          path: `crm/v3/objects/companies`,
          method: "POST",
          params: {
            properties: {
              name: `${values.outletBusinessName}${isDev ? "- TEST" : ""}`,
              country: values.country,
              city: values.state,
              state: values.state,
              zip: values.postcode,
              address: values.formattedAddress,
            },
            associations: [
              {
                to: {
                  id: contactId,
                },
                types: [
                  {
                    associationCategory: "HUBSPOT_DEFINED",
                    associationTypeId: 280,
                  },
                ],
              },
            ],
          },
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_HUBSPOT_API_SERVER_KEY,
            "Content-Type": "application/json",
          },
        }
      );
      setFormScreen(3);
    } catch (error) {
      renderErrorToast(
        "An error occurred!",
        "Something went wrong while registering your company. Please try again later or contact support",
        "error-creating-company-1"
      );
    }
  };

  console.log("sda", formik.values);

  useEffect(() => {
    if (!countryAddress) return;
    const googleScript = document.createElement("script");
    googleScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_ADDRESS_MAP_KEY}&libraries=places`;
    googleScript.async = true;
    googleScript.defer = true;

    googleScript.onload = () => {
      // Initialize Autocomplete after Google Maps script is loaded
      if (inputRef.current) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          inputRef.current,
          {
            types: ["geocode"],
          }
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          const { address_components } = place;
          formik.setFieldValue("latitude", place.geometry?.location.lat());
          formik.setFieldValue("longitude", place.geometry?.location.lng());
          formik.setFieldValue("storeAddress", place?.formatted_address || "");
          setCountryAddress(place?.formatted_address || "");
          const { long_name: country, short_name: countryCode } =
            address_components.find((el) => el.types.includes("country"));
          formik.setFieldValue("country", country || "");
          formik.setFieldValue("countryCode", countryCode || "");
          const { long_name: state } = address_components.find((el) =>
            el.types.includes("administrative_area_level_1")
          );
          formik.setFieldValue("state", state);
          const postalCode = address_components.find((el) =>
            el.types.includes("postal_code")
          );
          formik.setFieldValue("postcode", postalCode?.long_name || "");
          setCountryAddress(place.formatted_address || "");
          formik.setFieldValue(
            "formattedAddress",
            place.formatted_address || ""
          );
        });
      }
    };

    document.head.appendChild(googleScript);
    return () => {
      // Cleanup: remove the script when the component unmounts
      document.head.removeChild(googleScript);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryAddress]); // Empty dependency array ensures that this useEffect runs only once on mount

  return (
    <AuthWrapper
      children={
        selectedUserType === RETAILER ? (
          <RetailerForm
            formik={formik}
            loading={loading}
            setCountryAddress={setCountryAddress}
            inputRef={inputRef}
            formScreen={formScreen}
          />
        ) : selectedUserType === PARTNER ? (
          <PartnerForm
            formik={formik}
            loading={loading}
            formScreen={formScreen}
            setCountryAddress={setCountryAddress}
            inputRef={inputRef}
          />
        ) : (
          <SelectRegistrationType
            setSelectedUserType={setSelectedUserType}
            setFormScreen={setFormScreen}
            formik={formik}
          />
        )
      }
      page={formScreen || selectedUserType.length}
      onGoBack={() => {
        if (formScreen > 0) setFormScreen(formScreen - 1);
        if (formScreen === 1 && selectedUserType) {
          setSelectedUserType("");
          return formik.resetForm();
        }
      }}
    />
  );
};

const SelectRegistrationType = ({
  setSelectedUserType,
  setFormScreen,
  formik,
}) => {
  return (
    <div>
      <h3>How will you use TradeDepot?</h3>
      <h6>Select an option below</h6>
      <div className='option'>
        <BuildingIcon />
        <div>
          <h4>I'm a Retailer</h4>
          <p>
            Explore our curated selection of brands. Whether you’re a store
            owner or a reseller, TradeDepot offers you a portfolio of
            top-quality goods across various categories.
          </p>
          <button
            onClick={() => {
              setSelectedUserType(RETAILER);
              setFormScreen(1);
              formik.resetForm();
              window.history.replaceState({}, "");
              formik.setFieldValue("selectedUserType", RETAILER);
            }}
          >
            Next
          </button>
        </div>
      </div>
      <div className='option'>
        <UserCircleIcon />
        <div>
          <h4>I'm a Distribution Partner</h4>
          <p>
            Partner with TradeDepot to expand your business. Join our global
            network of importers and distributors to efficiently bring quality
            products into new markets.
          </p>
          <button
            onClick={() => {
              setSelectedUserType(PARTNER);
              setFormScreen(1);
              formik.resetForm();
              window.history.replaceState({}, "");
              formik.setFieldValue("selectedUserType", PARTNER);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

const RetailerForm = ({
  formik,
  loading,
  formScreen,
  setCountryAddress,
  inputRef,
}) => {
  const [storeTypes, setStoreTypes] = useState([]);

  useEffect(() => {
    if (!formik.values.countryCode) return;
    handleFetchOutletTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.countryCode]);

  useEffect(() => {
    if (!formik.values?.storeAddress) return;
    if (formik.values?.storeAddress) {
      setStoreTypes([]);
      formik.setFieldValue("outletTypeId", "");
      formik.setFieldValue("country", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.storeAddress]);

  const handleFetchOutletTypes = async () => {
    setStoreTypes([]);
    formik.setFieldValue("outletTypeId", "");
    try {
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_TD_APP_URL}/api/v4/procurement/retail/get-outlet-types?country=${formik.values.countryCode}`
      );
      setStoreTypes(data);
    } catch (error) {
      console.log({ error });
      renderErrorToast(
        "An error occurred!",
        "Something went wrong while fetching outlet types. Please try again later or contact support",
        "error-fetching-outlet-types-1"
      );
    }
  };

  return (
    <div className='retailer-form'>
      <h3>Sign up as a Retailer</h3>
      {formScreen === 1 ? (
        <>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Company Name'
                value={formik.values.outletBusinessName?.trimStart()}
                onChange={formik.handleChange}
                id='outletBusinessName'
              />
              {formik.errors.outletBusinessName ? (
                <span>{formik.errors.outletBusinessName}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Company address'
                value={formik.values.storeAddress}
                onChange={({ target: { value } }) => {
                  if (value.length < 3) formik.setFieldValue("countryCode", "");
                  setCountryAddress(value?.trimStart());
                  formik.setFieldValue("storeAddress", value?.trimStart());
                }}
                ref={inputRef}
                minLength={3}
              />
              {formik.errors.storeAddress ? (
                <span>{formik.errors.storeAddress}</span>
              ) : null}
            </div>
          </div>
          {formik.values.countryCode === "GB" && (
            <div className='input-flex'>
              <div>
                <input
                  placeholder='Post Code'
                  value={formik.values.postcode?.trimStart()}
                  onChange={formik.handleChange}
                  id='postcode'
                />
                {formik.errors.postcode ? (
                  <span>{formik.errors.postcode}</span>
                ) : null}
              </div>
            </div>
          )}
          <div className='input-flex'>
            <div className=''>
              <CustomSelect
                disabled={!storeTypes.length}
                items={storeTypes.map((el) => ({
                  title: el.name,
                  value: el._id,
                }))}
                placeholder='Type of Business'
                action={({ value }) =>
                  formik.setFieldValue("outletTypeId", value)
                }
                valueToDisplay={
                  storeTypes.find((el) => el._id === formik.values.outletTypeId)
                    ?.name
                }
              />
            </div>
          </div>
          <div className='input-flex'>
            <button
              disabled={
                formik.values.outletBusinessName?.length < 3 ||
                (formik?.values?.storeAddress || "")?.trim()?.length < 3 ||
                formik.values.outletTypeId?.length < 3 ||
                formik.values.storeAddress !== formik.values.formattedAddress ||
                loading
              }
              onClick={() => formik.handleSubmit()}
            >
              Continue
            </button>
          </div>
        </>
      ) : (
        <>
          <p className='sub-heading'>Fill in your contact information</p>
          <div className='input-flex' style={{ display: "none" }}>
            <div>
              <input placeholder='Testing something' />
            </div>
          </div>
          <div className='input-flex'>
            <div>
              <input
                placeholder='First Name'
                value={formik.values.firstName}
                onChange={formik.handleChange}
                id='firstName'
              />
              {formik.errors.firstName ? (
                <span>{formik.errors.firstName}</span>
              ) : null}
            </div>
            <div>
              <input
                placeholder='Last Name'
                value={formik.values.lastName?.trimStart()}
                onChange={formik.handleChange}
                id='lastName'
              />
              {formik.errors.lastName ? (
                <span>{formik.errors.lastName}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Contact Email'
                value={formik.values.contactEmail?.trimStart() || ""}
                id='contactEmail'
                onChange={formik.handleChange}
              />
              {formik.errors.contactEmail ? (
                <span>{formik.errors.contactEmail}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Contact Phone Number'
                value={formik.values.contactPhoneNumber?.trimStart() || ""}
                id='contactPosition'
                onChange={({ target: { value } }) => {
                  if (value.length < 15)
                    return formik.setFieldValue("contactPhoneNumber", value);
                }}
              />
              {formik.errors.contactPhoneNumber ? (
                <span>{formik.errors.contactPhoneNumber}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Your Position (e.g Buyer)'
                value={formik.values.contactPosition?.trimStart() || ""}
                id='contactPosition'
                onChange={formik.handleChange}
              />
              {formik.errors.outletPhoneNumber ? (
                <span>{formik.errors.outletPhoneNumber}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <button
              disabled={
                formik.values.contactPosition?.length < 3 ||
                formik.values.firstName?.length < 2 ||
                formik.values.lastName?.length < 2 ||
                formik.values.contactEmail?.length < 5 ||
                formik.values.contactPhoneNumber?.length < 3 ||
                loading
              }
              onClick={() => formik.handleSubmit()}
            >
              {loading ? <Loader /> : "Continue"}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const PartnerForm = ({
  formik,
  loading,
  formScreen,
  setCountryAddress,
  inputRef,
}) => {
  return (
    <div className='retailer-form'>
      {formScreen === 3 ? null : <h3>Sign up as a Partner</h3>}
      {formScreen === 1 ? (
        <>
          <p className='sub-heading'>Fill in your company information</p>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Company Name'
                value={formik.values.outletBusinessName}
                onChange={formik.handleChange}
                id='outletBusinessName'
              />
              {formik.touched.outletBusinessNamee &&
              formik.errors.outletBusinessName ? (
                <span>{formik.errors.outletBusinessName}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Organization address'
                value={formik.values.storeAddress}
                onChange={({ target: { value } }) => {
                  if (value.length < 3) formik.setFieldValue("countryCode", "");
                  setCountryAddress(value?.trimStart());
                  formik.setFieldValue("storeAddress", value?.trimStart());
                }}
                ref={inputRef}
                id='storeAddress'
                minLength={3}
              />
              {formik.touched.storeAddress && formik.errors.storeAddress ? (
                <span>{formik.errors.storeAddress}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <button
              disabled={
                formik.values.outletBusinessName?.length < 3 ||
                (formik?.values?.storeAddress || "")?.trim()?.length < 3 ||
                loading
              }
              onClick={() => formik.handleSubmit()}
            >
              {loading ? <Loader /> : "Continue"}
            </button>
          </div>
        </>
      ) : formScreen === 2 ? (
        <>
          <p className='sub-heading'>Fill in your contact information</p>
          <div className='input-flex'>
            <div>
              <input
                placeholder='First Name'
                value={formik.values.firstName?.trimStart()}
                onChange={formik.handleChange}
                id='firstName'
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <span>{formik.errors.firstName}</span>
              ) : null}
            </div>
            <div>
              <input
                placeholder='Last Name'
                value={formik.values.lastName?.trimStart()}
                onChange={formik.handleChange}
                id='lastName'
              />
              {formik.touched.lastName && formik.errors.lastName ? (
                <span>{formik.errors.lastName}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex' style={{ display: "none" }}>
            <div>
              <input placeholder='Testing something' />
            </div>
          </div>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Contact Email'
                value={formik.values.contactEmail?.trimStart()}
                onChange={formik.handleChange}
                id='contactEmail'
              />
              {formik.touched.contactEmail && formik.errors.contactEmail ? (
                <span>{formik.errors.contactEmail}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Contact Phone Number'
                value={formik.values.contactPhoneNumber?.trimStart()}
                onChange={formik.handleChange}
                id='contactPhoneNumber'
              />
              {formik.touched.contactPhoneNumber &&
              formik.errors.contactPhoneNumber ? (
                <span>{formik.errors.contactPhoneNumber}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <div>
              <input
                placeholder='Contact Position'
                value={formik.values.contactPosition?.trimStart()}
                onChange={formik.handleChange}
                id='contactPosition'
              />
              {formik.touched.contactPosition &&
              formik.errors.contactPosition ? (
                <span>{formik.errors.contactPosition}</span>
              ) : null}
            </div>
          </div>
          <div className='input-flex'>
            <button
              disabled={
                formik.values.firstName?.length < 3 ||
                formik.values.lastName?.length < 3 ||
                formik.values.contactEmail?.length < 3 ||
                formik.values.contactPhoneNumber?.length < 3 ||
                formik.values.contactPosition?.length < 3 ||
                loading
              }
              onClick={() => formik.handleSubmit()}
            >
              {loading ? <Loader /> : "Continue"}
            </button>
          </div>
        </>
      ) : formScreen === 3 ? (
        <div className='register-contact-success'>
          <div className='icon-wrapper'>
            <MessageIcon />
          </div>
          <h5>Thanks for signing up to our distribution network!</h5>
          <p> We have sent you an email detailing the next steps.</p>
        </div>
      ) : null}
    </div>
  );
};
