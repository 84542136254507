/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../assets/calendarsvg";
import Skeleton from "react-loading-skeleton";
import { Orders as OrdersSvg } from "../assets/orders";
import { Checkbox } from "../assets/checkbox";
import { useLocation, useNavigate } from "react-router-dom";
import {
  capitalizeStatusText,
  errorHandler,
  formatMoney,
  handlePrintOrder,
  isAParentOutlet,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import moment from "moment";
import { Pagination } from "../components/pagination";
import { relativeStatus } from "../utils/constants";
import { useReactToPrint } from "react-to-print";
import { PurchaseOrderDocument } from "../components/purchase-order-document.template";
import { Loader } from "../components/loader";
import { DeleteModal } from "../components/modals/delete-modal";

export function Orders() {
  const [selectedDates, setSelectedDates] = useState([]);
  const { apiKey, retailOutlets: [retailOutlet] = [] } = LSUserData() || {};
  const [orders, setOrders] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [printing, setPrinting] = useState(false);
  const [pagination, setPagination] = useState({ totalCount: 0 });
  const [actionLoading, setActionLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [draftSelectedOutlets, setDraftSelectedOutlets] = useState([]);
  const [openOutletFilter, setOpenOutletFilter] = useState(false);
  const [orderStatus, setOrderStatus] = useState("all");
  const [searchText, setSearchText] = useState("");
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedActionId, setSelectedActionId] = useState(null);
  const [selectedOrderToDelete, setSelectedOrderToDelete] = useState(null);
  const { branches = [] } = retailOutlet || {};
  const location = useLocation();
  const navigate = useNavigate();
  const endDate = selectedDates[1];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  console.log({ currentPage });

  useEffect(() => {
    scrollBackToTop();
    if (location?.state?.filter) setOrderStatus(location.state.filter);
  }, []);

  const allBranches = useMemo(() => {
    return [
      ...branches,
      {
        _id: retailOutlet._id,
        outletBusinessName: retailOutlet.outletBusinessName,
        streetName: retailOutlet.formattedAddress,
        contactPhone: retailOutlet.contactPhone || "",
      },
    ];
  }, [branches]);

  useEffect(() => {
    if (searchText.length) return;
    fetchOrders();
  }, [
    orderStatus,
    endDate,
    selectedDates.length,
    selectedOutlets,
    searchText,
    currentPage,
  ]);

  useEffect(() => {
    if (!selectedOrder?.orderReference) return;
    handlePrint();
    setSelectedOrder({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder]);

  useEffect(() => {
    setSearchText("");
    setCurrentPage(1);
    setSelectedOutlets([]);
    setSelectedDates([]);
  }, [orderStatus]);

  useEffect(() => {
    if (!searchText && !searchText?.length) return;
    console.log({ searchText });
    const runFunc = setTimeout(async () => {
      fetchOrders();
    }, 2000);
    return () => clearTimeout(runFunc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    if (openOutletFilter) setDraftSelectedOutlets([...selectedOutlets]);
  }, [openOutletFilter, selectedOutlets]);

  const fetchOrders = async () => {
    if (selectedDates.length && !endDate) return;
    const filters = {};
    if (orderStatus && orderStatus !== "all") {
      if (orderStatus === "pending") filters.status = "open";
      else if (orderStatus === "completed") filters.status = "shipped";
      else filters.status = orderStatus;
    }
    if (searchText && searchText.length) filters.searchText = searchText;
    if (searchText && searchText.length && searchText.startsWith("01"))
      filters.reference = searchText;
    if (selectedDates && selectedDates.length === 2) {
      filters.startDate = selectedDates[0];
      if (selectedDates[1])
        filters.endDate = moment(selectedDates[1]).endOf("day");
    }
    if (selectedOutlets.length) filters.branchOutletIds = selectedOutlets;
    const queryString = new URLSearchParams(filters).toString();
    setLoadingScreen(true);
    try {
      const {
        data: {
          data: { transactions, pagination: paginationOptions },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_APP_URL}/api/v3/procurement/transactions?${queryString}&page=${currentPage}`,
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      setOrders(transactions);
      setPagination({
        ...paginationOptions,
        totalCount: paginationOptions.totalPages * paginationOptions.perPage,
        page: Number(paginationOptions.page),
      });
    } catch (error) {
      const { message } = errorHandler(error);
      renderErrorToast("Error fetching orders", message, "fetching-orders-err");
    } finally {
      setLoadingScreen(false);
    }
  };

  const handleAddRemoveOutlet = (outletId) => {
    const doesKeyExist = draftSelectedOutlets.find(
      (el) => String(el) === String(outletId)
    );
    if (doesKeyExist) {
      setDraftSelectedOutlets([
        ...draftSelectedOutlets.filter((el) => String(el) !== String(outletId)),
      ]);
    } else {
      setDraftSelectedOutlets([...draftSelectedOutlets, String(outletId)]);
    }
  };

  const saveSelectedOutlets = () => {
    setSelectedOutlets([...draftSelectedOutlets]);
    setDraftSelectedOutlets([]);
  };

  const branchesMap = new Map(
    allBranches.map((branch) => [branch._id, branch])
  );

  const hasFilter = useMemo(() => {
    const filters = {};
    if (orderStatus && orderStatus !== "all") filters.status = orderStatus;
    if (searchText && searchText.length) filters.searchText = searchText;
    if (searchText && searchText.length && searchText.startsWith("01"))
      filters.reference = searchText;
    if (selectedDates && selectedDates.length === 2) {
      filters.startDate = selectedDates[0];
      if (selectedDates[1]) filters.endDate = selectedDates[1];
    }
    if (selectedOutlets.length)
      filters.branchOutletIds = encodeURIComponent(selectedOutlets);
    if (Object.keys(filters).length) return true;
    return false;
  }, [searchText, orderStatus, selectedDates, selectedOutlets]);

  const handleDeleteDraft = async (draftOrderId, retailOutletId) => {
    if (!draftOrderId) return renderErrorToast("Error deleting draft order");
    try {
      setActionLoading(true);
      await axios.delete(
        `${process.env.REACT_APP_AWS_API_URL_V2}/draft-order`,
        {
          data: { _id: draftOrderId, retailOutletId },
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      setSelectedActionId(null);
      renderErrorToast(
        "Action successful!",
        "Your draft order has been deleted successfully",
        "draft-order-delete-success"
      );
      fetchOrders();
    } catch (error) {
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error deleting draft order",
        message,
        "delete-draft-err-1"
      );
    } finally {
      setActionLoading(false);
      setSelectedActionId(null);
    }
  };

  return (
    <div
      className='tdp_orders'
      onClick={() => {
        setOpenOutletFilter(false);
      }}
    >
      <div className='header page-header'>
        <h2>Purchase Orders</h2>
        <div className='_filter_status'>
          <div className='order_statuses'>
            <button
              className={orderStatus === "all" ? "active" : ""}
              onClick={() => {
                setOrderStatus("all");
                scrollBackToTop();
              }}
            >
              All orders
            </button>
            <button
              className={orderStatus === "draft" ? "active" : ""}
              onClick={() => {
                setOrderStatus("draft");
                scrollBackToTop();
              }}
            >
              Draft
            </button>
            <button
              className={orderStatus === "pending" ? "active" : ""}
              onClick={() => {
                setOrderStatus("pending");
                scrollBackToTop();
              }}
            >
              Pending
            </button>
            <button
              className={orderStatus === "completed" ? "active" : ""}
              onClick={() => {
                setOrderStatus("completed");
                scrollBackToTop();
              }}
            >
              Completed
            </button>
          </div>
          <div className='__filters'>
            <div className='date-picker-container'>
              <DatePicker
                placeholderText='Filter Date'
                monthsShown={2}
                onInputClick={() => setSelectedDates([])}
                onChange={(dates) => {
                  setSelectedDates(dates);
                  scrollBackToTop();
                }}
                startDate={selectedDates[0]}
                endDate={selectedDates[1]}
                selectsRange
                dateFormat='MMM d'
              />
              <CalendarIcon />
            </div>

            <div
              className='custom-filter-select'
              onClick={(e) => e.stopPropagation()}
            >
              <select
                className='form-select'
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenOutletFilter(!openOutletFilter);
                  scrollBackToTop();
                }}
                disabled={!isAParentOutlet()}
              />
              <span onClick={() => setOpenOutletFilter(!openOutletFilter)}>
                {!selectedOutlets.length ||
                selectedOutlets.length === allBranches.length
                  ? "All branches"
                  : selectedOutlets.length === 1
                  ? allBranches.find((el) => el._id === selectedOutlets[0])
                      .outletBusinessName
                  : `${selectedOutlets.length} selected`}
              </span>
              {openOutletFilter ? (
                <div className='__select-dropdown'>
                  {allBranches.length ? (
                    <>
                      {allBranches.map((el, key) => (
                        <li
                          key={key}
                          onClick={() => {
                            handleAddRemoveOutlet(el._id);
                            scrollBackToTop();
                          }}
                        >
                          <Checkbox
                            isChecked={draftSelectedOutlets.find(
                              (outletId) => String(outletId) === String(el._id)
                            )}
                          />
                          <div>
                            <h4>{el.outletBusinessName}</h4>
                            <h6>{el.streetName || "-"}</h6>
                          </div>
                        </li>
                      ))}
                      <div className='__actions'>
                        <button onClick={() => setOpenOutletFilter(false)}>
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            saveSelectedOutlets();
                            setOpenOutletFilter(false);
                          }}
                        >
                          Select
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <li>
                        <div>
                          <h4>No Outlet</h4>
                          <h6>
                            <i>There are no branches for this outlet</i>
                          </h6>
                        </div>
                      </li>
                    </>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className='body page-body list-body'>
        {
          <div className='__input-search'>
            <i className='bi bi-search'></i>
            <input
              className='form-control order-search'
              placeholder='Type to search by reference number or destination'
              onChange={({ target: { value } }) => {
                setSearchText(value);
                scrollBackToTop();
              }}
            />
          </div>
        }
        <div className='orders-list'>
          {loadingScreen ? (
            <Skeleton count={10} />
          ) : !loadingScreen && !orders.length ? (
            <div className='empty__list'>
              <OrdersSvg />
              <h3>
                {hasFilter && orderStatus !== "all"
                  ? `No ${orderStatus} orders available`
                  : hasFilter
                  ? "No orders matching your filter criteria"
                  : "No Orders Yet!"}
              </h3>
              <p onClick={() => navigate("/create-order")}>
                Create a new order <i className='bi bi-arrow-right'></i>
              </p>
            </div>
          ) : !loadingScreen ? (
            <div className='entities'>
              <table class='table'>
                <thead>
                  <tr>
                    <th scope='col'>Amount</th>
                    <th scope='col'>Reference</th>
                    <th scope='col' colSpan={2}>
                      Summary
                    </th>
                    <th scope='col'>Delivering to</th>
                    <th scope='col'>Created On</th>
                    <th scope='col' colSpan={2}></th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, key) => (
                    <tr
                      key={key}
                      onClick={() =>
                        navigate(`/console/order/${order._id}`, {
                          state: {
                            isDraft: order.status === "pending",
                            draftOrder:
                              order.status === "pending" ? order : null,
                          },
                        })
                      }
                    >
                      <td className='amount'>
                        <p>
                          <span>
                            {order?.currency?.symbol}
                            {formatMoney(
                              (order?.draftOrderNumber
                                ? order.orderTotal
                                : order.amount) || 0
                            )}
                          </span>
                          <span>{order?.currency?.iso || "-"}</span>
                        </p>
                      </td>
                      <td>
                        {order.reference || order?.draftOrderNumber || "-"}
                      </td>
                      <td colSpan={2}>
                        <span className='summary'>
                          {order?.summary ||
                            order?.items?.map(
                              (el, key) =>
                                `${el.name} ${order.items[key + 1] ? ", " : ""}`
                            )}
                        </span>
                      </td>
                      <td className='amount'>
                        <span>
                          {branchesMap.get(order.retailOutletId)
                            ?.outletBusinessName || "-"}
                        </span>
                      </td>
                      <td>
                        <span>{moment(order.createdAt).format("Do MMM")}</span>
                        {orderStatus === "all" ? (
                          <span
                            className={`custom_status ${relativeStatus(
                              order.status === "pending"
                                ? "draft"
                                : order.status === "open"
                                ? "pending"
                                : order.status
                            )}`}
                          >
                            {order.status === "pending"
                              ? "Draft"
                              : order.status === "open"
                              ? "Pending"
                              : capitalizeStatusText(order.status)}
                          </span>
                        ) : null}
                      </td>
                      <td
                        onClick={(e) => e.stopPropagation()}
                        colSpan={2}
                        className='actions-td'
                      >
                        <span
                          role='button'
                          className={`action-row-button ${
                            printing ? "disabled" : ""
                          }`}
                          onClick={async (e) => {
                            e.stopPropagation();
                            setPrinting(true);
                            if (printing) return;
                            await handlePrintOrder(
                              order,
                              setPrinting,
                              setSelectedOrder
                            );
                          }}
                        >
                          {printing ? <Loader /> : "Print Order"}
                        </span>
                        {order?.draftOrderNumber ? (
                          <span
                            role='button'
                            className={`action-row-button ${
                              actionLoading && order._id === selectedActionId
                                ? "disabled"
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedActionId(order._id);
                              setSelectedOrderToDelete(order);
                            }}
                            data-bs-toggle='modal'
                            data-bs-target='#deleteModal'
                          >
                            {actionLoading && order._id === selectedActionId ? (
                              <Loader />
                            ) : (
                              "Delete"
                            )}
                          </span>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                dataCount={pagination.totalCount}
                perPage={pagination?.perPage}
                setCurrentPage={(page) => setCurrentPage(page)}
                currentPage={currentPage}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={componentRef} className='tdp_create_orders'>
          <div className='body'>
            <div className='right__ detail'>
              <PurchaseOrderDocument
                downloading={true}
                orderReference={selectedOrder?.orderReference || ""}
                order={selectedOrder?.order || {}}
                branch={
                  allBranches.find(
                    (el) => el._id === selectedOrder?.order?.retailOutletId
                  ) || {}
                }
                totalCalculation={selectedOrder?.totalCalculation || {}}
                currency={selectedOrder?.order?.currency?.symbol || ""}
                reviewOrderData={null}
                showPromotionValue={null}
                currencyCode={selectedOrder?.order?.currency?.iso || ""}
                poOrders={{ items: selectedOrder?.poOrders || [] }}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        draftOrderId={selectedActionId}
        deleteAction={() =>
          handleDeleteDraft(
            selectedOrderToDelete?._id,
            selectedOrderToDelete?.retailOutletId
          )
        }
        loading={actionLoading}
      />
    </div>
  );
}
