/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { CartIcon } from "../assets/cart.icon";
import {
  errorHandler,
  formatMoney,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import { Counter } from "../components/counter";
import { CategoryProductsDrawer } from "../components/drawers/category-products.drawer";
import { CatalogProductDetail } from "../components/drawers/catalog-product.drawer";
import axios from "axios";
import {
  LoadingProduct,
  ProductEmptyList,
  quantityError,
} from "../components/drawers/products.drawer";
import Skeleton from "react-loading-skeleton";
import Carousel from "react-multi-carousel";
import { useNavigate, useOutletContext } from "react-router-dom";
import { LS_CART_PRODUCTS, SUPPORTED_CURRENCIES } from "../utils/constants";
import { ImageWithFallback } from "./console";

export function Catalog() {
  const { selectedCurrency } = useOutletContext();
  const { apiKey = "", token = "", retailOutlets = [] } = LSUserData() || {};
  const VIEW_GRID = "view-grid";
  const VIEW_LIST = "view-list";
  const [viewtype, setViewType] = useState(VIEW_GRID);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedBrand, setSelectedBrand] = useState("all");
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [selectedCategoryProducts, setSelectedCategoryProducts] = useState({});
  const [cartProducts, setCartProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [outletVariants, setOutletVariants] = useState([]);
  const [collections, setCollections] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [priceFilter, setPriceFilter] = useState("normal");
  const [retailOutlet] = retailOutlets;
  const [globalProductSearchText, setGlobalProductSearchText] = useState("");
  const [rates, setRates] = useState([]);
  const { coordinates: { plusCode6Hex = "" } = {} } = retailOutlet || {};
  const navigate = useNavigate();

  useEffect(() => {
    scrollBackToTop();
    if (localStorage.getItem(LS_CART_PRODUCTS)) {
      setCartProducts(JSON.parse(localStorage.getItem(LS_CART_PRODUCTS)));
    }
    handleFetchConversionRates();
    handleFetchListings();
    handleFetchBrowseCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(LS_CART_PRODUCTS, JSON.stringify(cartProducts));
  }, [cartProducts]);

  useEffect(() => {
    if (!collections.length) return;
    setTimeout(() => {
      setLoadingProducts(false);
    }, 2000);
  }, [selectedCategory, priceFilter, viewtype, selectedBrand]);

  useEffect(() => {
    if (!collections.length) return;
    setLoadingProducts(true);
    setTimeout(() => {
      setLoadingProducts(false);
    }, 2000);
  }, [selectedCurrency]);

  const handleFetchConversionRates = async () => {
    try {
      const {
        data: {
          rates: { NGN, EUR, GBP, USD },
        },
      } = await axios.get(
        `${process.env.REACT_APP_EXCHANGE_RATES_API}?app_id=${process.env.REACT_APP_EXCHANGE_RATES_KEY}&base=USD`
      );
      setRates({ NGN, EUR, GBP, USD });
    } catch (error) {
      console.log("sdss", error, "conversion error");
    }
  };

  const handleFetchListings = async () => {
    try {
      const {
        data: { body },
      } = await axios.get(
        `${process.env.REACT_APP_AWS_API_URL_V3}/outlet-variants`,
        { headers: { "x-api-key": apiKey } }
      );
      setOutletVariants(body);
    } catch (error) {
      console.log("fetch listings error", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error fetching outlet variants",
        message,
        "fetching-variants-err-2"
      );
    }
  };

  const handleFetchBrowseCollection = async () => {
    try {
      const {
        data: { collection },
      } = await axios.get(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v5/procurement/getPageCollection?page=browse&plusCode6Hex=${plusCode6Hex}`,
        { headers: { Authorization: `Bearer ${token}`, "x-api-key": apiKey } }
      );
      setCollections(collection);
      setLoadingProducts(false);
    } catch (error) {
      console.log("fetch browswe collection error", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error collection outlet products",
        message,
        "fetching-variants-err-3"
      );
    }
  };

  const filterVariants = (variants) => {
    if (priceFilter === "normal") return variants;
    if (priceFilter === "low-to-high")
      return variants.sort((a, b) => a.price - b.price);
    if (priceFilter === "high-to-low")
      return variants.sort((a, b) => b.price - a.price);
  };

  const productsPerCategory = useMemo(() => {
    let outletListings = [];
    const { value: currency, symbol } = SUPPORTED_CURRENCIES.find(
      (el) => el.title === selectedCurrency
    );
    if (outletVariants.length) {
      outletListings = [
        {
          categoryName: "Your Listings",
          products: filterVariants(outletVariants).map((variant) => ({
            ...variant,
            convertedPrice: (
              variant.price * (rates ? 1 / rates[currency] : 1)
            ).toFixed(2),
            convertedCurrency: symbol,
          })),
        },
      ];
    }
    const collectionListings = collections.reduce((acc, curr) => {
      let productVariants = curr.productVariants.map((variant) => ({
        ...variant,
        convertedPrice: (
          variant.price * (rates ? 1 / rates[currency] : 1)
        ).toFixed(2),
        convertedCurrency: symbol,
      }));
      if (productVariants.length)
        return [
          ...acc,
          {
            categoryName: curr.name,
            products: filterVariants(productVariants),
          },
        ];
      else return acc;
    }, []);
    const listings = [...outletListings, ...collectionListings];
    return listings;
  }, [
    outletVariants,
    collections,
    priceFilter,
    filterVariants,
    selectedCurrency,
  ]);

  const selectedCategoryProductSearchResult = useMemo(() => {
    if (!selectedCategoryProducts?.products?.length) return [];
    if (!searchText) return selectedCategoryProducts?.products || [];
    return selectedCategoryProducts?.products.filter((el) =>
      el.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [selectedCategoryProducts?.products, searchText]);

  const handleAddProduct = (productId) => {
    let localCartProducts = [...cartProducts];
    const existingSelectedProductWithProductIdIndex =
      localCartProducts.findIndex((el) => el._id === productId);
    if (existingSelectedProductWithProductIdIndex < 0) {
      const allProducts = productsPerCategory.flatMap((el) => el.products);
      const product = allProducts.find((el) => el._id === productId);
      const initialCount = 1;
      const currentSupplyQty = product?.extvariants?.available;
      if (initialCount > currentSupplyQty)
        return quantityError(currentSupplyQty);
      localCartProducts = [
        ...localCartProducts,
        { ...product, count: initialCount },
      ];
    } else {
      const product = localCartProducts.find((el) => el._id === productId);
      const count = Number(product.count) + 1;
      const currentSupplyQty = product?.extvariants?.available;
      if (count > currentSupplyQty) return quantityError(currentSupplyQty);
      localCartProducts[existingSelectedProductWithProductIdIndex] = {
        ...product,
        count,
      };
      localCartProducts = [...localCartProducts];
    }
    setCartProducts([...localCartProducts]);
    renderErrorToast(
      "Your Cart",
      `${localCartProducts.length} items in cart`,
      "add-cart-success",
      <>
        <div className=''></div>
        <button>Create order</button>
      </>
    );
  };

  const handleRemoveProduct = (productId) => {
    const existingSelectedProductWithProductIdIndex = cartProducts.findIndex(
      (el) => el._id === productId
    );
    if (existingSelectedProductWithProductIdIndex < 0) return;
    const product = cartProducts[existingSelectedProductWithProductIdIndex];
    if (!product) return;
    const newCount = Number(product.count) - 1;
    if (!newCount) {
      const remainingProducts = cartProducts.filter(
        (el) => el._id !== productId
      );
      return setCartProducts([...remainingProducts]);
    }
    cartProducts[existingSelectedProductWithProductIdIndex] = {
      ...product,
      count: newCount,
    };
    setCartProducts([...cartProducts]);
  };

  const handleInputProductQuantity = (productId, value) => {
    if (value < 1) return;
    const existingSelectedProductWithProductIdIndex = cartProducts.findIndex(
      (el) => el._id === productId
    );
    if (existingSelectedProductWithProductIdIndex < 0) return;
    const product = cartProducts[existingSelectedProductWithProductIdIndex];
    const currentSupplyQty = product?.extvariants?.available;
    if (currentSupplyQty > Number(value)) {
      cartProducts[existingSelectedProductWithProductIdIndex] = {
        ...product,
        count: Number(value),
      };
      return setCartProducts([...cartProducts]);
    }
    return quantityError(currentSupplyQty);
  };

  const categoriesName = productsPerCategory.map((el) => el.categoryName);
  let brands = productsPerCategory
    .filter((el) => el.categoryName === selectedCategory)
    .flatMap((el) => el.products)
    .map((el) => el.brandName);
  brands = [...new Set(brands)].filter((el) => el.length > 0);

  return (
    <div className='tdp_orders tdp_catalog'>
      <div className='header page-header'>
        <h2>Catalog</h2>
        <div className='_filter_status'>
          <div className='order_statuses'>
            <button
              className={`${selectedCategory === "all" ? "active" : ""}`}
              onClick={() => {
                if (selectedCategory !== "all") setLoadingProducts(true);
                setSelectedCategory("all");
                setSelectedBrand("all");
                scrollBackToTop();
              }}
            >
              All
            </button>
            {categoriesName.map((category, key) => (
              <button
                className={`${
                  selectedCategory.toLowerCase() === category.toLowerCase()
                    ? "active"
                    : ""
                }`}
                key={key}
                onClick={() => {
                  setLoadingProducts(true);
                  setSelectedCategory(category);
                  setSelectedBrand("all");
                  scrollBackToTop();
                }}
              >
                {category}
              </button>
            ))}
          </div>
        </div>
        <div className='search_filter'>
          <div className='search-container'>
            <i className='bi bi-search'></i>
            <input
              placeholder='Search'
              onChange={({ target: { value } }) =>
                setGlobalProductSearchText(value)
              }
              value={globalProductSearchText}
            />
          </div>
          <div className='types-filter'>
            <div className='selects-group'>
              <select
                className='form-select'
                onChange={({ target: { value } }) => {
                  if (priceFilter !== value) setLoadingProducts(true);
                  setPriceFilter(value);
                  window.scrollTo({ top: 0 });
                }}
              >
                <option value={"normal"} selected>
                  Price: Default
                </option>
                <option value={"low-to-high"}>Price: Low to High</option>
                <option value={"high-to-low"}>Price: High to Low</option>
              </select>
              <select
                value={selectedCategory}
                onChange={({ target: { value } }) => {
                  if (selectedCategory !== value) setLoadingProducts(true);
                  window.scrollTo({ top: 0 });
                  setSelectedCategory(value);
                  setSelectedBrand("all");
                }}
              >
                <option selected disabled>
                  Category
                </option>
                <option value={"all"}>All Categories</option>
                {categoriesName.map((el, key) => (
                  <option key={key} value={el}>
                    {el}
                  </option>
                ))}
              </select>
              <select
                value={selectedBrand}
                onChange={({ target: { value } }) => {
                  if (selectedBrand !== value) setLoadingProducts(true);
                  setSelectedBrand(value);
                  window.scrollTo({ top: 0 });
                }}
              >
                <option selected disabled>
                  Brands
                </option>
                <option value={"all"}>All Brands</option>
                {brands.map((el, key) => (
                  <option key={key} value={el}>
                    {el}
                  </option>
                ))}
              </select>
            </div>
            <div className='view-types'>
              <button
                className={viewtype === VIEW_GRID ? "active" : ""}
                onClick={() => {
                  setLoadingProducts(true);
                  setViewType(VIEW_GRID);
                  setGlobalProductSearchText("");
                  setSelectedCategory("all");
                }}
              >
                <i className='bi bi-grid'></i>
              </button>
              <button
                className={viewtype === VIEW_LIST ? "active" : ""}
                onClick={() => {
                  setViewType(VIEW_LIST);
                  setGlobalProductSearchText("");
                  setSelectedCategory("all");
                }}
              >
                <i className='bi bi-list'></i>
              </button>
            </div>
            <button
              className='cart-btn'
              onClick={() =>
                navigate("/create-order", {
                  state: { selectedProducts: cartProducts },
                })
              }
            >
              <CartIcon />
              <span>{cartProducts.length}</span>
            </button>
          </div>
        </div>
      </div>
      <div className='body page-body'>
        {viewtype === VIEW_GRID ? (
          <ViewProductsGridStyle
            productsPerCategory={productsPerCategory}
            loading={loadingProducts}
            setSelectedCategoryProducts={setSelectedCategoryProducts}
            setSearchText={setSearchText}
            handleAddProduct={handleAddProduct}
            handleRemoveProduct={handleRemoveProduct}
            cartProducts={cartProducts}
            handleInputProductQuantity={handleInputProductQuantity}
            globalProductSearchText={globalProductSearchText}
            setSelectedProduct={setSelectedProduct}
            selectedCategory={selectedCategory}
            selectedBrand={selectedBrand}
          />
        ) : (
          <ViewProductsListStyle
            productsPerCategory={productsPerCategory}
            loading={loadingProducts}
            handleAddProduct={handleAddProduct}
            handleRemoveProduct={handleRemoveProduct}
            cartProducts={cartProducts}
            handleInputProductQuantity={handleInputProductQuantity}
            globalProductSearchText={globalProductSearchText}
            setSelectedProduct={setSelectedProduct}
            selectedCategory={selectedCategory}
            searchText={searchText}
            selectedBrand={selectedBrand}
          />
        )}
      </div>
      <CategoryProductsDrawer
        products={selectedCategoryProductSearchResult}
        categoryName={selectedCategoryProducts?.categoryName}
        cartProducts={cartProducts}
        setSearchText={setSearchText}
        searchText={searchText}
        handleAddProduct={handleAddProduct}
        handleInputProductQuantity={handleInputProductQuantity}
        handleRemoveProduct={handleRemoveProduct}
      />
      <CatalogProductDetail
        product={selectedProduct}
        handleAddProduct={handleAddProduct}
        cartProducts={cartProducts}
        handleInputProductQuantity={handleInputProductQuantity}
        handleRemoveProduct={handleRemoveProduct}
      />
    </div>
  );
}

const ViewProductsGridStyle = ({
  productsPerCategory = [],
  loading = true,
  setSelectedCategoryProducts,
  setSearchText,
  globalProductSearchText,
  handleAddProduct,
  handleRemoveProduct,
  handleInputProductQuantity,
  cartProducts = [],
  setSelectedProduct,
  selectedCategory,
  selectedBrand,
}) => {
  const filteredProductsPerCategory = useMemo(() => {
    let products = productsPerCategory;
    if (selectedCategory.toLowerCase() !== "all")
      products = productsPerCategory.filter(
        (el) => el.categoryName.toLowerCase() === selectedCategory.toLowerCase()
      );
    if (selectedBrand.toLowerCase() !== "all") {
      products = products.reduce((acc, curr) => {
        let productVariants = curr.products;
        productVariants = productVariants.filter(
          (product) =>
            product.brandName.toLowerCase() === selectedBrand.toLowerCase()
        );
        if (productVariants.length)
          return [
            ...acc,
            { categoryName: curr.categoryName, products: productVariants },
          ];
        else return acc;
      }, []);
    }
    if (globalProductSearchText) {
      products = products.reduce((acc, curr) => {
        let productVariants = curr.products;
        productVariants = productVariants.filter((product) =>
          product.name
            .toLowerCase()
            .includes(globalProductSearchText.toLowerCase())
        );
        if (productVariants.length)
          return [
            ...acc,
            { categoryName: curr.categoryName, products: productVariants },
          ];
        else return acc;
      }, []);
    }
    return products;
  }, [
    productsPerCategory,
    selectedCategory,
    globalProductSearchText,
    selectedBrand,
  ]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3200, min: 24 },
      items: 5,
      slidesToSlide: 5, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    return (
      <div className='slider-buttons carousel-button-group'>
        <button
          className={
            currentSlide === 0
              ? "disable"
              : "" +
                "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
          }
          onClick={() => previous()}
        />
        <button
          className={
            currentSlide === totalItems - slidesToShow ||
            totalItems <= slidesToShow
              ? "disable"
              : "" +
                "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
          }
          onClick={() => next()}
        />
      </div>
    );
  };

  return (
    <div className='grid-view-container'>
      {loading ? (
        [1, 2, 3, 4].map((_, key) => (
          <div className='products-loading-screen' key={key}>
            <LoadingProduct length={7} />
          </div>
        ))
      ) : !loading && filteredProductsPerCategory.length ? (
        filteredProductsPerCategory.map((category, key) => (
          <div className='product_section' key={key}>
            <div className='product_title'>
              <h3>{category.categoryName}</h3>
              <p
                data-bs-toggle='offcanvas'
                href='#offcanvasCategoryProducts'
                aria-controls='offcanvasCategoryProducts'
                onClick={() => {
                  setSearchText("");
                  setSelectedCategoryProducts({
                    categoryName: category.categoryName,
                    products: productsPerCategory.find(
                      (el) => el.categoryName === category.categoryName
                    ).products,
                  });
                }}
              >
                View all <i className='bi bi-arrow-right'></i>
              </p>
            </div>
            <div className='products_container'>
              <Carousel
                responsive={responsive}
                renderButtonGroupOutside={true}
                arrows={false}
                customButtonGroup={<ButtonGroup />}
              >
                {category?.products.map((product, index) => (
                  <div
                    className='product'
                    key={product?._id || index}
                    onClick={() =>
                      setSelectedProduct({
                        ...product,
                        similarItems: [
                          ...category.products.filter(
                            (el) => el._id !== product._id
                          ),
                        ],
                      })
                    }
                  >
                    {product?.promotion ? <p className='promo'>Promo</p> : null}
                    <ImageWithFallback
                      src={`${process.env.REACT_APP_PRODUCT_URL}/${product.variantId}.png`}
                      fallback={`https://td-dev-img.s3.amazonaws.com/xs/${product.variantId}.png`}
                      alt={product.name}
                      data-bs-toggle='offcanvas'
                      href='#offcanvasCatalogProduct'
                      key={product?._id}
                      aria-controls='offcanvasCatalogProduct'
                    />
                    <div className='details'>
                      <h5
                        data-bs-toggle='offcanvas'
                        href='#offcanvasCatalogProduct'
                        aria-controls='offcanvasCatalogProduct'
                      >
                        {product?.convertedCurrency}
                        {formatMoney(product?.convertedPrice)}
                        {/* {product.currency.symbol}
                        {formatMoney(product.price)} */}
                      </h5>
                      <div
                        data-bs-toggle='offcanvas'
                        href='#offcanvasCatalogProduct'
                        aria-controls='offcanvasCatalogProduct'
                        style={{ height: "50px" }}
                      >
                        <p>{product.name}</p>
                      </div>

                      {cartProducts.find(
                        (cartProduct) => cartProduct._id === product._id
                      ) ? (
                        <Counter
                          cartProducts={cartProducts}
                          product={product}
                          handleAddProduct={handleAddProduct}
                          handleRemoveProduct={handleRemoveProduct}
                          handleInputProductQuantity={
                            handleInputProductQuantity
                          }
                        />
                      ) : (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddProduct(product._id);
                          }}
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        ))
      ) : (
        <ProductEmptyList searchText={globalProductSearchText} />
      )}
    </div>
  );
};

const ViewProductsListStyle = ({
  productsPerCategory = [],
  handleAddProduct,
  handleRemoveProduct,
  handleInputProductQuantity,
  cartProducts = [],
  loading,
  globalProductSearchText,
  setSelectedProduct,
  selectedCategory,
  selectedBrand,
}) => {
  const allProducts = useMemo(() => {
    let products = productsPerCategory.reduce((acc, curr) => {
      const { products } = curr;
      return [
        ...acc,
        ...products.map((el) => ({
          ...el,
          customCategoryName: curr.categoryName,
        })),
      ];
    }, []);

    return products;
  }, [productsPerCategory]);

  const filteredProducts = useMemo(() => {
    let products = allProducts;
    if (selectedCategory.toLowerCase() !== "all")
      products = allProducts
        .filter(
          (el) =>
            el.customCategoryName.toLowerCase() ===
            selectedCategory.toLowerCase()
        )
        .reduce((acc, curr) => {
          const { products } = curr;
          return [
            ...acc,
            ...products.map((el) => ({
              ...el,
              customCategoryName: curr.categoryName,
            })),
          ];
        }, []);
    if (globalProductSearchText)
      products = products.filter((product) =>
        product.name
          .toLowerCase()
          .includes(globalProductSearchText.toLowerCase())
      );
    if (selectedBrand.toLowerCase() !== "all")
      products = products.filter(
        (product) =>
          product.brandName.toLowerCase() === selectedBrand.toLowerCase()
      );
    return products;
  }, [allProducts, selectedCategory, globalProductSearchText, selectedBrand]);

  return (
    <div className='orders-list list-view-container'>
      <div className='entities'>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col'></th>
              <th scope='col' colSpan={3}>
                Product Name
              </th>
              <th scope='col' colSpan={2}>
                Inventory
              </th>
              <th scope='col' colSpan={2}>
                Category
              </th>
              <th scope='col' colSpan={2}>
                Price
              </th>
              <th scope='col' colSpan={2}></th>
              <th scope='col' colSpan={3}></th>
              <th scope='col' colSpan={3}></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={17}>
                  <Skeleton count={12} />
                </td>
              </tr>
            ) : !loading && filteredProducts.length ? (
              filteredProducts.map((product, index) => (
                <tr
                  key={index}
                  onClick={() =>
                    setSelectedProduct({
                      ...product,
                      similarItems: allProducts
                        .filter(
                          (el) =>
                            el.customCategoryName.toLowerCase() ===
                            product.customCategoryName.toLowerCase()
                        )
                        .filter((el) => el._id !== product._id),
                    })
                  }
                >
                  <td
                    className='img-col'
                    data-bs-toggle='offcanvas'
                    href='#offcanvasCatalogProduct'
                    aria-controls='offcanvasCatalogProduct'
                  >
                    <ImageWithFallback
                      src={`${process.env.REACT_APP_PRODUCT_URL}/${product.variantId}.png`}
                      fallback={`https://td-dev-img.s3.amazonaws.com/xs/${product.variantId}.png`}
                      alt={product.name}
                      data-bs-toggle='offcanvas'
                      href='#offcanvasCatalogProduct'
                      aria-controls='offcanvasCatalogProduct'
                      isListScreen={true}
                      key={product?._id}
                    />
                  </td>
                  <td
                    colSpan={3}
                    data-bs-toggle='offcanvas'
                    href='#offcanvasCatalogProduct'
                    aria-controls='offcanvasCatalogProduct'
                  >
                    <p className='product-name'>{product.name}</p>
                  </td>
                  <td
                    colSpan={2}
                    data-bs-toggle='offcanvas'
                    href='#offcanvasCatalogProduct'
                    aria-controls='offcanvasCatalogProduct'
                  >
                    {formatMoney(product?.extvariants?.available || 0)}
                  </td>
                  <td
                    colSpan={2}
                    data-bs-toggle='offcanvas'
                    href='#offcanvasCatalogProduct'
                    aria-controls='offcanvasCatalogProduct'
                  >
                    {product?.categoryGroup || product?.category || "-"}
                  </td>
                  <td
                    data-bs-toggle='offcanvas'
                    href='#offcanvasCatalogProduct'
                    aria-controls='offcanvasCatalogProduct'
                    colSpan={2}
                  >
                    {product?.currency?.symbol}
                    {formatMoney(product?.price)}
                    {product?.promotion ? (
                      <span className='promo'>Promo Item</span>
                    ) : null}
                  </td>
                  <td
                    colSpan={2}
                    data-bs-toggle='offcanvas'
                    href='#offcanvasCatalogProduct'
                    aria-controls='offcanvasCatalogProduct'
                  ></td>
                  <td
                    colSpan={3}
                    className={`counter ${
                      cartProducts.find(
                        (cartProduct) => cartProduct._id === product?._id
                      )?.count > 0
                        ? "show"
                        : ""
                    }`}
                  >
                    <Counter
                      cartProducts={cartProducts}
                      handleAddProduct={handleAddProduct}
                      handleInputProductQuantity={handleInputProductQuantity}
                      handleRemoveProduct={handleRemoveProduct}
                      product={product}
                    />
                  </td>
                  <td colSpan={3}>
                    <button
                      className='addToCart'
                      onClick={() => handleAddProduct(product._id)}
                    >
                      Add to Cart
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={17}>
                <ProductEmptyList searchText={globalProductSearchText} />
              </td>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
