/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  errorHandler,
  formatMoney,
  LSUserData,
  processOrderCalculations,
  renderErrorToast,
} from "../utils/functions";
import { relativeStatus } from "../utils/constants";
import { PurchaseOrderDocument } from "./purchase-order-document.template";
import Skeleton from "react-loading-skeleton";

export function OrderView() {
  const navigate = useNavigate();
  const componentRef = useRef();
  const { apiKey, retailOutlets: [retailOutlet] = [] } = LSUserData() || {};
  const { branches = [], country = "" } = retailOutlet || {};
  const { orderId } = useParams();
  const location = useLocation();
  const isDraft = location?.state?.isDraft || false;
  const draftOrder = location?.state?.draftOrder || {};
  const [orders, setOrders] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ukTrackingStatuses, setUkTrackingStatuses] = useState([]);
  const [trackingCode, setTrackingCode] = useState("");
  const [shipment, setShipment] = useState(null);
  const isCountryUK = country === "GB";
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const allBranches = useMemo(() => {
    return [
      ...branches,
      {
        _id: retailOutlet?._id,
        outletBusinessName: retailOutlet?.outletBusinessName,
        streetName:
          retailOutlet?.formattedAddress || retailOutlet?.streetName || "",
        contactPhone: retailOutlet?.contactPhone || "",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches]);

  const branch = useMemo(() => {
    if (!selectedTransaction && !draftOrder) return;
    if (!allBranches.length) return;
    let transaction = selectedTransaction;
    if (isDraft) {
      transaction = draftOrder;
      if (loading) setLoading(false);
    }
    if (!transaction) return;
    return allBranches.find((el) => el._id === transaction.retailOutletId);
  }, [allBranches, selectedTransaction, draftOrder, isDraft]);

  useEffect(() => {
    if (!isDraft) getOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrderDetails = async () => {
    if (!orderId) return;
    try {
      const {
        data: {
          data: { orders, transaction, shipment },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_APP_URL}/api/v3/procurement/transactions/${orderId}`,
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      setOrders(orders);
      setSelectedTransaction(transaction);
      if (shipment.length) setShipment(shipment[0]);
      setLoading(false);
    } catch (error) {
      console.log("error getting order details");
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error fetching order details",
        message,
        "err-fetching-order-details"
      );
    }
  };

  const copyText = async (text) => {
    await navigator.clipboard.writeText(text);
    renderErrorToast(
      "Code Copied!",
      "Driver's code has been copied successfully!",
      "copy-code-success"
    );
  };

  const {
    items,
    toAddress,
    subtotal,
    total,
    currencySymbol,
    currency,
    discounts,
    shippingFees,
    taxes,
  } = useMemo(() => {
    let items = [];
    let subtotal = 0,
      shippingFees = 0;
    let total = 0;
    let currencySymbol = "";
    let currency = "";
    let fromAddress = "-";
    let toAddress = "-";
    let discounts = 0;
    let taxes = 0;
    if (!isDraft && !orders.length)
      return {
        items,
        subtotal,
        total,
        currencySymbol,
        toAddress,
        fromAddress,
        discounts,
        taxes,
        shippingFees,
        currency,
      };
    if (isDraft && !draftOrder) return;
    if (!isDraft) {
      //       let d = item.discount || 0;
      // if (!item.isPromo && item.status !== 'deleted') {
      //   total = ((item.quantity * item.price * ((100 - d) / 100)));
      // }
      // is an order
      const processedCalculations = processOrderCalculations(
        orders,
        selectedTransaction
      );
      items = processedCalculations.items;
      discounts = processedCalculations.discounts;
      total = processedCalculations.total;
      subtotal = processedCalculations.subtotal;
      shippingFees = processedCalculations.shippingFees;
      taxes = processedCalculations.taxes;
      currencySymbol = selectedTransaction?.currency?.symbol || "";
      currency = selectedTransaction.currency.iso || "";
      toAddress = branch?.streetName || "-";
      fromAddress =
        retailOutlet?.formattedAddress || retailOutlet?.streetName || "-";
    }
    if (isDraft) {
      items = draftOrder.items.map((el) => ({
        ...el,
        total: el.price * el.quantity,
      }));
      subtotal = draftOrder.orderTotal;
      total = draftOrder.orderTotal;
      currency = draftOrder.currency.iso || "";
      toAddress = branch?.streetName || "-";
      fromAddress = retailOutlet?.formattedAddress || "-";
      currencySymbol = draftOrder.currency.symbol;
    }
    return {
      items,
      subtotal,
      total,
      currencySymbol,
      toAddress,
      fromAddress,
      discounts,
      shippingFees,
      taxes,
      currency,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDraft,
    orders,
    draftOrder,
    selectedTransaction?.currency?.symbol,
    selectedTransaction?.retailOutletId,
    retailOutlet?.formattedAddress,
    branch,
  ]);

  const handleGetTrackingDetailsById = async () => {
    try {
      if (!shipment)
        return renderErrorToast(
          "Cannot fetch tracking",
          "Shipment does not exist for this order",
          "error-fetching-tracking-1"
        );
      const {
        data: {
          data: { orderStatus, trackingCode },
        },
      } = await axios.get(
        `${process.env.REACT_APP_TD_CONSOLE_URL}/api/v3/order-shipment/tracking/${shipment._id}`,
        { headers: { "x-api-key": apiKey } }
      );
      setUkTrackingStatuses(orderStatus);
      setTrackingCode(trackingCode);
    } catch (error) {
      console.log("tracking details", error);
      setLoading(false);
    }
  };

  const trackingStatus = useMemo(() => {
    if (!orders.length) return {};
    if (isCountryUK && !ukTrackingStatuses.length) {
      handleGetTrackingDetailsById();
      return {};
    }
    if (isCountryUK && ukTrackingStatuses.length) {
      const orderPlaced = ukTrackingStatuses.find(
        (el) => el.status === "order placed"
      );
      const orderScheduled = ukTrackingStatuses.find(
        (el) => el.status === "scheduled"
      );
      const orderOutForDelivery = ukTrackingStatuses.find(
        (el) => el.status === "out for delivery"
      );
      const orderDelivered = ukTrackingStatuses.find(
        (el) => el.status === "delivered"
      );
      return {
        isOrderPlaced: !!orderPlaced,
        orderPlacedDate: orderPlaced?.createdAt || null,
        isOrderConfirmed: !!orderScheduled,
        orderConfirmedDate: orderScheduled?.createdAt || null,
        isOrderOutForDelivery: !!orderOutForDelivery,
        orderOutForDeliveryDate: orderOutForDelivery?.createdAt || null,
        isOrderDelivered: !!orderDelivered,
        orderDeliveredDate: orderDelivered?.createdAt || null,
      };
    }
    const [order] = orders;
    const { shippingStatus } = order;
    const isOrderDelivered = ["delivered", "shipped"].includes(shippingStatus);
    const isOrderOutForDelivery =
      ["dispatched"].includes(shippingStatus) || isOrderDelivered;
    const isOrderConfirmed =
      ["scheduled", "processing", "skipped", "ready"].includes(
        shippingStatus
      ) || isOrderOutForDelivery;
    const isOrderPlaced =
      ["pending", "cancelled"].includes(shippingStatus) || isOrderConfirmed;
    const orderPlacedDate = isOrderPlaced ? orders[0].createdAt : null;
    const orderConfirmedDate = isOrderConfirmed ? orders[0].scheduledAt : null;
    const orderOutForDeliveryDate = isOrderOutForDelivery
      ? orders[0].shippedAt
      : null;
    const orderDeliveredDate = isOrderDelivered ? orders[0].deliveredAt : null;
    return {
      orderConfirmedDate,
      orderDeliveredDate,
      orderPlacedDate,
      orderOutForDeliveryDate,
      isOrderConfirmed,
      isOrderPlaced,
      isOrderOutForDelivery,
      isOrderDelivered,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, isCountryUK, ukTrackingStatuses]);

  return (
    <div className='tdp_order_view'>
      <div className='tdp_onboarding'>
        <div className='header'>
          <div className='cancel__'>
            <div onClick={() => navigate(-1)}>
              <i className='bi bi-x-lg'></i>
            </div>
            <h4>Order Summary</h4>
          </div>
          <div className='buttons_flex'>
            <button onClick={handlePrint}>Print</button>
            {isDraft ? (
              <button
                onClick={() => navigate("/create-order", { state: draftOrder })}
              >
                Edit
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className='body'>
        <div className='container-fluid'>
          <div className={`_card-boxes ${isDraft ? "draft" : ""}`}>
            <div className='_left'>
              <div className='title'>
                <h2>
                  Order{" "}
                  {isDraft
                    ? draftOrder.draftOrderNumber
                    : selectedTransaction?.reference}
                </h2>
                <div className='due_status_block'>
                  {isDraft ? (
                    <span className='draft'>Draft</span>
                  ) : (
                    <span
                      className={`custom_status ${relativeStatus(
                        selectedTransaction?.status
                      )}`}
                    >
                      {selectedTransaction?.status}
                    </span>
                  )}
                </div>
              </div>
              <div className='date'>
                <h4>Date</h4>
                <h6>
                  {selectedTransaction?.createdAt || draftOrder?.createdAt
                    ? moment(
                        selectedTransaction?.createdAt || draftOrder?.createdAt
                      ).format("Do MMM, YYYY")
                    : "-"}
                </h6>
              </div>
              <div className='location'>
                <h4>Deliver to</h4>
                {loading ? (
                  <Skeleton />
                ) : (
                  <h6>
                    <p>{branch?.outletBusinessName || "-"}</p>
                    <p>{toAddress}</p>
                  </h6>
                )}
              </div>
              <div className='_custom-table'>
                <div className='_head'>
                  <p>Description</p>
                  <p>Qty</p>
                  <p>Unit Price</p>
                  <p>Discount</p>
                  <p>Tax</p>
                  <p>Amount</p>
                </div>
                <div className='_body'>
                  {loading
                    ? [1, 2].map(() => (
                        <tr>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      ))
                    : items.map((el, key) => (
                        <tr key={key}>
                          <td className='item-1'>{el.name}</td>
                          <td className='item-2'>{el.quantity}</td>
                          <td className='item-2'>
                            {`${currencySymbol}${formatMoney(el.price || 0)}`}
                          </td>
                          <td className='item-1'>
                            {`${currencySymbol}${formatMoney(
                              el.discount || 0
                            )}`}
                          </td>
                          <td className='item-1'>
                            {currencySymbol} {el.tax || 0}
                          </td>
                          <td className='item-1'>
                            {`${currencySymbol}${formatMoney(el.total || 0)}`}
                          </td>
                        </tr>
                      ))}
                </div>
                <div className='_totals'>
                  <div className='total-line'>
                    <p>Subtotal</p>
                    <p>
                      {currencySymbol} {formatMoney(subtotal || 0)}
                    </p>
                  </div>
                  <div className='total-line'>
                    <p>Discounts</p>
                    <p>
                      {currencySymbol} {formatMoney(discounts || 0)}
                    </p>
                  </div>
                  {taxes ? (
                    <div className='total-line'>
                      <p>Taxes</p>
                      <p>
                        {currencySymbol} {formatMoney(taxes)}
                      </p>
                    </div>
                  ) : null}
                  {selectedTransaction &&
                  selectedTransaction?.country === "GB" ? (
                    <div className='total-line'>
                      <p>Shipping Fees</p>
                      <p>
                        {currencySymbol} {formatMoney(shippingFees)}
                      </p>
                    </div>
                  ) : null}
                  <div className='total-line'>
                    <p>Total</p>
                    <p>
                      {currencySymbol} {formatMoney(total)}
                    </p>
                  </div>
                  <div className='total-line total'>
                    <p>
                      {draftOrder?.draftOrderNumber
                        ? "Estimated Amount"
                        : "Amount Due"}
                    </p>
                    <p>
                      {currencySymbol} {formatMoney(total)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {!isDraft ? (
              <div className='_right'>
                <h2>Order Tracking</h2>
                <div className='tracking'>
                  <div
                    className={`tracking-status ${
                      trackingStatus.isOrderPlaced ? "completed" : ""
                    }`}
                  >
                    <div className='tracking-details'>
                      <div className=''>
                        <h4>Order Placed</h4>
                        {trackingStatus.isOrderPlaced ? (
                          <h6>
                            {moment(orders[0].createdAt).format("DD/MM/YYYY")}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tracking-status ${
                      trackingStatus.isOrderConfirmed ? "completed" : ""
                    }`}
                  >
                    <div className='tracking-details'>
                      <div className=''>
                        <h4>Order Confirmed</h4>
                        {trackingStatus.isOrderConfirmed ? (
                          <h6>
                            {moment(orders[0].scheduledAt).format("DD/MM/YYYY")}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tracking-status ${
                      trackingStatus.isOrderOutForDelivery ? "completed" : ""
                    }`}
                  >
                    <div className='tracking-details'>
                      <div className=''>
                        <h4>Out for Delivery</h4>
                        {trackingStatus.isOrderOutForDelivery ? (
                          <h6>
                            {moment(orders[0].shippedAt).format("DD/MM/YYYY")}
                          </h6>
                        ) : null}
                        {trackingStatus.isOrderOutForDelivery ? (
                          <>
                            {orders[0]?.dispatchUser?.name ? (
                              <div className='driver_details'>
                                <p>Driver name</p>
                                <p>{orders[0].dispatchUser?.name}</p>
                              </div>
                            ) : null}

                            {trackingCode ? (
                              <div className='driver_details'>
                                <p>Delivery Code</p>
                                <p>
                                  {trackingCode}{" "}
                                  <button
                                    onClick={() => copyText(trackingCode)}
                                  >
                                    Copy code
                                  </button>
                                </p>
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tracking-status ${
                      trackingStatus.isOrderDelivered ? "completed" : ""
                    }`}
                  >
                    <div className='tracking-details'>
                      <div className=''>
                        <h4>Delivered</h4>
                        {trackingStatus.isOrderDelivered ? (
                          <h6>
                            {moment(orders[0].deliveredAt).format("DD/MM/YYYY")}
                          </h6>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={componentRef} className='tdp_create_orders'>
          <div className='body'>
            <div className='right__ detail'>
              <PurchaseOrderDocument
                downloading={!isDraft}
                orderReference={
                  draftOrder?.draftOrderNumber ||
                  selectedTransaction?.reference ||
                  ""
                }
                branch={branch}
                totalCalculation={{
                  subtotal,
                  discount: discounts,
                  total,
                  amountDue: total,
                  taxes,
                  shippingFees,
                }}
                currency={currencySymbol}
                reviewOrderData={null}
                showPromotionValue={null}
                currencyCode={currency}
                poOrders={{
                  items: items.map((el) => ({
                    ...el,
                    count: el.quantity,
                    currency: { symbol: currencySymbol },
                  })),
                }}
                order={draftOrder || selectedTransaction || null}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
