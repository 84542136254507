import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { EditSvg } from "../assets/edit";
import { SideProducts } from "../components/drawers/products.drawer";
import { DeleteSvg } from "../assets/delete";
import {
  errorHandler,
  formatMoney,
  getCountryDetails,
  getRandomAlphanumericNumbers,
  isAParentOutlet,
  LSUserData,
  renderErrorToast,
  scrollBackToTop,
} from "../utils/functions";
import {
  DOCU_SIGN_OUTLETS,
  ITEMS_OUT_OF_STOCK,
  LS_CART_PRODUCTS,
  SHOP,
} from "../utils/constants";
import { AddPhoneNumberDrawer } from "../components/drawers/drawer-add-phone-number";
import { Loader } from "../components/loader";
import { PurchaseOrderDocument } from "../components/purchase-order-document.template";
import { ReviewOrderDrawer } from "../components/drawers/review-order.drawer";
import { CustomSelect } from "../components/custom-select";
import { toast } from "react-toastify";
const DRAFT_ACTION = "creating-draft-order";
const ORDER_ACTION = "creating-order";

export function CreateOrders() {
  const navigate = useNavigate();
  const { retailOutlets = [], apiKey, userId, token } = LSUserData() || {};
  const [showBranches, setShowBranches] = useState(false);
  const [branch, setBranch] = useState({ edit: true, value: {} });
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [preparedOrders, setPreparedOrders] = useState(null);
  const [showProductSearchInput, setShowProductSearchInput] = useState(true);
  const [productsInReview, setProductsInReview] = useState([]);
  const [variants, setVariants] = useState([]);
  const [outletVariants, setOutletVariants] = useState([]);
  const [collectionProducts, setCollectionProducts] = useState([]);
  const [fetchingProducts, setFetchingProducts] = useState(true);
  const [creatingDraft, setCreatingDraft] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [action, setAction] = useState("");
  const [retailOutlet] = retailOutlets;
  const {
    branches,
    country,
    contactPhone,
    isB2B,
    coordinates: { plusCode6Hex } = {},
  } = retailOutlet || {};
  const { currency, currencyCode } = getCountryDetails(country);
  const [orderReference, setOrderReference] = useState("");
  const [reviewOrderData, setReviewOrderData] = useState(null);
  const [note, setNote] = useState("");
  const [reviewProductValueChanged, setReviewProductValueChanged] =
    useState(false);
  const { state } = useLocation();

  useEffect(() => {
    if (!showProductSearchInput && showProductsDropdown) {
      setShowProductsDropdown(false);
      setVariants([]);
    }
  }, [showProductSearchInput, showProductsDropdown]);

  useEffect(() => {
    scrollBackToTop();
    fetchAllVariants();
    setOrderReference(getRandomAlphanumericNumbers());
    if (!isAParentOutlet() || !isB2B) {
      setBranch({
        edit: false,
        outletBusinessName: retailOutlet.outletBusinessName,
        streetName: retailOutlet.streetName,
        _id: retailOutlet._id,
        value: {
          name: retailOutlet.outletBusinessName,
          address: retailOutlet.streetName,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllVariants = async () => {
    try {
      setFetchingProducts(true);
      const [collection, outletVariants] = await Promise.all([
        fetchCollection(),
        fetchOutletVariants(),
      ]);
      setCollectionProducts(collection);
      setOutletVariants(outletVariants);
      setFetchingProducts(false);
    } catch (error) {
      renderErrorToast(
        "Oops!",
        "There was an error fetching products. Please try again later.",
        "err-products-fetch-1"
      );
    }
  };

  useState(() => {
    if (state?.selectedProducts) {
      if (state.selectedProducts?.length > 1)
        setSelectedProducts([
          ...state.selectedProducts.map((el, key) => ({
            ...el,
            count: el.count || 1,
            key,
          })),
        ]);
      else
        setProductsInReview([
          ...state.selectedProducts.map((el, key) => ({
            ...el,
            count: el.count || 1,
            key,
          })),
        ]);
    }
  }, []);

  useEffect(() => {
    // set branch when state lands
    if (state?._id) {
      if (!branch?._id) {
        const branchUsedInOrder = (branches || []).find(
          (branch) => branch._id === state.retailOutletId
        );
        if (branchUsedInOrder) {
          setBranch({
            edit: false,
            outletBusinessName: branchUsedInOrder.outletBusinessName,
            streetName: branchUsedInOrder.streetName,
            _id: branchUsedInOrder._id,
            value: {
              name: branchUsedInOrder.outletBusinessName,
              address: branchUsedInOrder.streetName,
            },
          });
        } else {
          setBranch({
            edit: false,
            outletBusinessName: retailOutlet.outletBusinessName,
            streetName: retailOutlet.streetName,
            _id: retailOutlet._id,
            value: {
              name: retailOutlet.outletBusinessName,
              address: retailOutlet.streetName,
            },
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchText) return;
    if (showProductsDropdown === true) setShowProductsDropdown(false);
    const runSearchFunction = setTimeout(async () => {
      searchProductCollection();
    }, 1200);
    return () => clearTimeout(runSearchFunction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    const reviewOrderDrawer = document.getElementById("offcanvasrevieworder");
    const handleCloseReviewOrderDrawer = () => {
      setProductsInReview([]);
      setReviewProductValueChanged(false);
    };
    // Bootstrap's event when modal is hidden
    reviewOrderDrawer?.addEventListener("hidden.bs.offcanvas", () =>
      handleCloseReviewOrderDrawer()
    );
    return () => {
      reviewOrderDrawer.removeEventListener(
        "hidden.bs.offcanvas",
        handleCloseReviewOrderDrawer()
      );
    };
  }, []);

  const collectionVariants = useMemo(() => {
    return collectionProducts.flatMap((collection) =>
      collection.productVariants.map((el) => ({
        ...el,
        collectionId: collection._id,
        collectionName: collection.name,
      }))
    );
  }, [collectionProducts]);

  useEffect(() => {
    if (!state?._id && !state?.retailOutletId) return;
    const allVariants = [...outletVariants, ...collectionVariants];
    if (!selectedProducts.length && allVariants.length) {
      const items = state.items.reduce((acc, curr) => {
        const variant = allVariants.find(
          (variant) => variant.variantId === curr.variantId
        );
        if (variant) return [...acc, { ...variant, count: curr.quantity }];
        return acc;
      }, []);
      setSelectedProducts([...items]);
    }
    if (!note && state?.notes?.length)
      setNote(
        state.notes[0].body && typeof state.notes[0].body === "string"
          ? state.notes[0].body
          : state.notes && typeof state.notes === "string"
          ? state.notes
          : ""
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants, collectionVariants]);

  const totalCalculation = useMemo(() => {
    if (!preparedOrders) {
      const subtotal = selectedProducts.reduce(
        (acc, curr) => acc + curr.price * curr.count,
        0
      );
      return { total: subtotal, subtotal, amountDue: subtotal };
    }
    const orders = (preparedOrders?.orders || []).filter(
      (order) => order.metadata.fulfillable
    );
    let total = orders.reduce((acc, curr) => {
      return acc + (curr.total || 0);
    }, 0);
    let subtotal = orders.reduce((acc, curr) => {
      return acc + (curr.subTotal || 0);
    }, 0);
    const discount = orders.reduce((acc, curr) => {
      if (!curr?.hasPromotions) return acc;
      let itemDiscount = curr?.discounts || 0;
      if (!itemDiscount)
        itemDiscount = curr?.items.reduce((acc2, curr2) => {
          if (!curr2.promoDiscount) return acc2;
          return acc2 + (curr2.promoDiscount || 0) * (curr2.quantity || 0);
        }, 0);
      return acc + itemDiscount || 0;
    }, 0);
    const taxes = orders.reduce((acc, curr) => acc + curr.taxes, 0);
    subtotal = subtotal + discount;
    if (reviewOrderData) {
      total =
        subtotal +
        (reviewOrderData?.shippingCost || 0) +
        (reviewOrderData?.tax || 0) +
        (reviewOrderData?.processingCost || 0) -
        discount;
    }
    total = total + taxes;
    return { total, subtotal, amountDue: total, discount, taxes };
  }, [preparedOrders, reviewOrderData, selectedProducts]);

  const poOrders = useMemo(() => {
    if (!preparedOrders) return { items: selectedProducts };
    const fulfillableOrders = preparedOrders.orders.filter(
      (order) => order.metadata.fulfillable
    );
    let items = fulfillableOrders.flatMap((el) => el.items);
    items = items.map((el) => ({
      ...el,
      discounts: el.discounts || (el.promoDiscount || 0) * el.quantity,
      count: el.quantity,
      currency: fulfillableOrders[0].currency,
    }));
    return { items };
  }, [preparedOrders, selectedProducts]);

  const fetchOutletVariants = async () => {
    try {
      const {
        data: { body },
      } = await axios.get(
        `${process.env.REACT_APP_AWS_API_URL_V3}/outlet-variants`,
        { headers: { "x-api-key": apiKey } }
      );
      return body;
    } catch (error) {
      const message = errorHandler(error);
      renderErrorToast(
        "Error fetching variants",
        message,
        "fetching-variants-err"
      );
      console.log({ error }, "fetching collection");
      setFetchingProducts(false);
      return [];
    }
  };

  const fetchCollection = async () => {
    try {
      const {
        data: { collection },
      } = await axios.get(
        `${process.env.REACT_APP_FIREBASE_URL}/shop/v5/procurement/getPageCollection?page=browse&plusCode6Hex=${plusCode6Hex}`,
        { headers: { Authorization: `Bearer ${token}`, "x-api-key": apiKey } }
      );
      return collection;
    } catch (error) {
      const message = errorHandler(error);
      renderErrorToast(
        "Error fetching variants",
        message,
        "fetching-variants-err"
      );
      console.log({ error }, "fetching collection");
      setFetchingProducts(false);
    }
  };

  // const fetchAreaBaskets = async () => {
  //   try {
  //     // const res = await
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };

  const searchedVariants = useMemo(() => {
    if (!searchText) return outletVariants;
    return outletVariants.filter((el) =>
      el.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [searchText, outletVariants]);

  const searchProductCollection = async () => {
    try {
      setFetchingProducts(true);
      setVariants([...searchedVariants]);
      if (!productsInReview.length) setShowProductsDropdown(true);
    } catch (error) {
      console.log("searching products", error);
    } finally {
      setFetchingProducts(false);
    }
  };

  const handleSyncProducts = (products) => {
    setPreparedOrders(null);
    const newProducts = products.filter(
      (product) => !selectedProducts.find((el) => el._id === product._id)
    );
    const prods = selectedProducts.reduce((acc, curr) => {
      const prod = products.find((product) => product._id === curr._id);
      if (prod) return [...acc, prod];
      return acc;
    }, []);
    setSelectedProducts([...prods, ...newProducts]);
    setShowProductSearchInput(false);
  };

  const handleSelectDropdownProduct = (product) => {
    // check to see if product exist amongst the already selected products
    const selectedProductValueIndex = selectedProducts.findIndex(
      (el) => el._id === product._id
    );
    // if index > -1, the product exists : in that case, just make an edit for the existing
    // product selected
    if (selectedProductValueIndex > -1) {
      setShowProductsDropdown(false);
      return setProductsInReview([
        {
          ...selectedProducts.find((el) => el._id === product._id),
          edit: true,
          key: selectedProductValueIndex,
        },
      ]);
    }
    // else, create newly
    setProductsInReview([{ ...product, count: 1 }]);
    setShowProductSearchInput(false);
    setShowProductsDropdown(false);
  };

  const cancelProductProcessing = (key, productId) => {
    const filteredProductsInReview = productsInReview.filter(
      (el) => el._id !== productId
    );
    setProductsInReview([...filteredProductsInReview]);
    if (!filteredProductsInReview.length) setReviewOrderData(null);
    if (!selectedProducts.length) setShowProductSearchInput(true);
  };

  const saveProductProcessing = () => {
    if (!productsInReview[0]?.count)
      return renderErrorToast(
        "Product Quantity error",
        "You must add at least one (qty) of this item to cart",
        "product-quantity-err-1"
      );
    setPreparedOrders(null);
    setShowProductsDropdown(false);
    setSelectedProducts([...selectedProducts, ...productsInReview]);
    setProductsInReview([]);
  };

  const selectProductForEdit = (key) => {
    // if (reviewOrderData) return;
    const product = selectedProducts[key];
    setProductsInReview([{ ...product, edit: true, key }]);
  };

  const handleEditProduct = (key) => {
    setPreparedOrders(null);
    const product = productsInReview.find((el) => el.key === key);
    if (!product?.count)
      return renderErrorToast(
        "Product Quantity error",
        "You must add at least one (qty) of this item to cart",
        "product-quantity-err-1"
      );
    selectedProducts[key] = {
      ...selectedProducts[key],
      count: product.count,
      price: product.price,
    };
    setShowProductsDropdown(false);
    setSelectedProducts([...selectedProducts]);
    setProductsInReview([]);
  };

  const handleRemoveProduct = (key, productId) => {
    setPreparedOrders(null);
    setReviewOrderData(null);
    const selectedProduct = selectedProducts.find((el) => el._id === productId);
    if (!selectedProducts.length) setShowProductSearchInput(true);
    setSelectedProducts([
      ...selectedProducts.filter((el) => el._id !== productId),
    ]);
    if (productsInReview.find((el) => el._id === selectedProduct._id)) {
      const productInReviewIndex = productsInReview.findIndex(
        (el) => el._id === selectedProduct._id
      );
      productsInReview.splice(productInReviewIndex, 1);
      setProductsInReview([...productsInReview]);
      if (!productsInReview.length) {
        setShowProductSearchInput(false);
      }
    }
  };

  const handlePrepareOrders = async () => {
    try {
      if (!selectedProducts.length) throw Error("Selected Products!!");
      const items = selectedProducts.map((el) => ({
        variantId: el.variantId,
        price: el.price,
        quantity: el.count,
        name: el.name,
        code: el.code,
        status: "open",
        couponCode: el.couponCode || "",
      }));
      const { contactPhone } = retailOutlet;
      const prepareOrderBody = {
        retailOutletId: branch._id,
        items,
        extChannel: SHOP.WEB,
        contactPhone,
        issuedAt: new Date(),
        preview: true,
      };
      if (state?.draftOrderNumber) prepareOrderBody.draftOrderId = state._id;
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_TD_APP_URL}/api/v2/retail/orders/prepare`,
        prepareOrderBody
      );
      setPreparedOrders(data);
      return data;
    } catch (error) {
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error preparing orders",
        message,
        "preparing-orders-err"
      );
      setCreatingDraft(false);
      setCreatingOrder(false);
      throw error;
    }
  };

  const handleGetShippingRates = async ({
    orders,
    retailOutletId,
    rawOrder,
    ...rest
  }) => {
    try {
      const fulfillableOrders = orders.filter((el) => el.metadata.fulfillable);
      if (!fulfillableOrders.length) {
        return renderErrorToast(
          "Error Creating Orders",
          "Cannot create orders with unfulfillable items",
          "error-creating-orders-unfulfillable"
        );
      }
      const {
        data: { data: ratesData },
      } = await axios.post(
        `${process.env.REACT_APP_TD_APP_URL}/api/v3/shipping/rate`,
        {
          orders: fulfillableOrders,
          retailOutletId: branch._id,
          // branchOutletId: branch._id,
        },
        {}
      );
      const preparedOrders = { ...rest, orders, retailOutletId, rawOrder };
      return { ratesData, rawOrder, preparedOrders };
    } catch (error) {
      console.log("getting shipping rates err", error);
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error getting shipping rates",
        message,
        "shipping-rates-err"
      );
      console.log("preparing order message", message);
      setCreatingDraft(false);
      setCreatingOrder(false);
      throw error;
    }
  };

  const handleNGUsersCreateCartOrder = async (doc) => {
    try {
      doc.rawOrder.notes = [{ userId, body: note }];
      await axios.post(
        `${process.env.REACT_APP_AWS_API_URL_V2}/create-cart`,
        {
          ...doc,
        },
        { headers: { "x-api-key": apiKey } }
      );
    } catch (error) {
      console.log("ereor", error);
      const { message, customCode } = errorHandler(error);
      if (customCode === 1251) {
        const {
          data: { data },
        } = await axios.post(
          `${process.env.REACT_APP_TD_APP_URL}/api/v2/retail/orders/prepare`,
          { ...doc.rawOrder }
        );
        console.log("new prepared orders", data);
        handleNGUsersCreateCartOrder(data);
      } else {
        console.log("errormessage", message);
        renderErrorToast(
          "Error creating cart order",
          message,
          "cart-order-err-2"
        );
        console.log("preparing order message", message);
        setCreatingDraft(false);
        setCreatingOrder(false);
        throw error;
      }
    }
  };

  const handleCreateCartOrder = async (ratesData, rawOrder, preparedOrders) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_AWS_API_URL_V2}/create-cart`,
        {
          ...preparedOrders,
        },
        { headers: { "x-api-key": apiKey } }
      );
    } catch (error) {
      console.log("ereor", error);
      const { message } = errorHandler(error);
      console.log("errormessage", message);
      renderErrorToast("Error creating cart order", message, "cart-order-err");
      setCreatingOrder(false);
      setCreatingDraft(false);
      throw error;
    }
  };

  const handleOrderCreateApiCall = async (preparedOrders) => {
    if (!preparedOrders) return;
    try {
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_AWS_API_URL}/create-order`,
        { ...preparedOrders },
        {
          headers: {
            "x-api-key": apiKey,
          },
        }
      );
      localStorage.removeItem(LS_CART_PRODUCTS);
      return data;
    } catch (error) {
      console.log({ error });
      const { message, customCode } = errorHandler(error);
      console.log("csss", customCode);
      if (customCode === 1252) {
        // const {
        //   data: { data },
        // } = await axios.post(
        //   `${process.env.REACT_APP_TD_APP_URL}/api/v2/retail/orders/prepare`,
        //   { ...preparedOrders.rawOrder }
        // );
        // handleNGUsersCreateCartOrder(data);
        // handleOrderCreateApiCall(data);
      } else if (message === "Payment Required for this Outlet") {
      } else {
        renderErrorToast("Error creating order", message, "create-order-err");
      }
      console.log("preparing order message", message);
      setCreatingOrder(false);
      setCreatingDraft(false);
      throw error;
    }
  };

  const updateDraftOrder = async () => {
    try {
      const fulfillableOrders = preparedOrders.orders.filter(
        (el) => el.metadata.fulfillable
      );
      const items = fulfillableOrders.flatMap((el) => el.items);
      const newDraft = {
        ...state,
        items,
        notes: note,
        retailOutletId: branch?._id || state?.retailOutletId,
      };
      const config = {
        headers: {
          "x-api-key": apiKey,
        },
      };
      await axios.put(
        `${process.env.REACT_APP_AWS_API_URL_V2}/draft-order`,
        newDraft,
        config
      );
    } catch (error) {
      throw error;
    }
  };

  const handleDraftOrderCreateApiCall = async (
    draftOrder = null,
    reroute = true
  ) => {
    setCreatingDraft(true);
    try {
      const config = {
        headers: {
          "x-api-key": apiKey,
        },
      };
      if (draftOrder) {
        // update draft order
        await updateDraftOrder();
      } else {
        // create new draft order
        await axios.post(
          `${process.env.REACT_APP_AWS_API_URL_V2}/draft-order`,
          {
            retailOutletId: preparedOrders.retailOutletId, // the branch outlet that the order is for
          },
          config
        );
      }
      if (reroute) {
        renderErrorToast(
          "Successful",
          draftOrder
            ? "We have updated your draft order"
            : "We have saved your order as draft",
          "success-draft-order-msg"
        );
        navigate("/console/orders");
      }
      setCreatingDraft(false);
      localStorage.removeItem(LS_CART_PRODUCTS);
    } catch (error) {
      console.log({ error });
      const { message } = errorHandler(error);
      renderErrorToast(
        "Error creating draft order",
        message,
        "create-draft-order-err"
      );
      console.log("preparing draft order message", message);
      setCreatingDraft(false);
      throw error;
    }
  };

  const processCreateOrder = async ({
    isCreatingDraftOrder = false,
    phoneNumberHasBeenAddedFromDrawer = false,
  }) => {
    if (!branch.outletBusinessName) {
      if (isCreatingDraftOrder)
        return renderErrorToast(
          "Cannot create draft",
          "You need to select a location before creating draft",
          "create-order-err--1"
        );
      if (!isCreatingDraftOrder)
        return renderErrorToast(
          "Cannot create order",
          "You need to select a location before creating order",
          "create-order-err--2"
        );
    }
    if (!selectedProducts.length)
      return renderErrorToast(
        "Cannot create order",
        "You must select an item before creating order",
        "create-order-err--3"
      );
    try {
      if (isCreatingDraftOrder) setCreatingDraft(true);
      if (!isCreatingDraftOrder) setCreatingOrder(true);
      setAction(isCreatingDraftOrder ? DRAFT_ACTION : ORDER_ACTION);
      if (!phoneNumberHasBeenAddedFromDrawer) {
        if (!contactPhone) {
          setCreatingDraft(false);
          setCreatingOrder(false);
          return document.getElementById("offcanvas-phone").click();
        }
      }
      document.getElementById("close-add-phone-number-drawer").click(); // close modal
      await handleReviewOrder({
        calledByDraft: isCreatingDraftOrder,
      });
    } catch (error) {
      console.log({ error });
      // throw error;
    }
  };

  const closeConfirmOrderDrawer = () => {
    document.getElementById("close-review-order-drawer").click();
  };

  const processPayment = async ({
    paymentMethod,
    orderTotal,
    beforeOrder = false,
    customConfig = null,
  }) => {
    const body = {
      creditBalance: 0,
      availableBalance: 0,
    };
    if (customConfig) {
      body.creditBalance = customConfig.creditBalance;
      body.availableBalance = customConfig.walletBalance;
    } else {
      if (paymentMethod === "credit") body.creditBalance = orderTotal;
      if (paymentMethod === "wallet") body.availableBalance = orderTotal;
    }
    if (beforeOrder)
      return await makePayment(body, { chargeBeforeOrder: true });
    if (!beforeOrder) {
      // make payment after order
      const order = await handleOrderCreateApiCall(preparedOrders);
      body.orderPaymentReference = order.reference;
      if (paymentMethod === "credit")
        body.creditBalance = order?.paymentTransaction?.amount ?? orderTotal;
      if (paymentMethod === "wallet")
        body.availableBalance = order?.paymentTransaction?.amount ?? orderTotal;
      await makePayment(body);
    }
  };

  const handleReturnOrderCreateErrOne = async (assigneeName = "") => {
    setCreatingOrder(false);
    if (!state?.draftOrderNumber)
      await handleDraftOrderCreateApiCall(null, false); // reroute = false
    else if (state?.draftOrderNumber) await updateDraftOrder();
    renderErrorToast(
      "An Issue Occurred",
      `We are unable to place your order right now. ${
        state?.draftOrderNumber
          ? "Your draft will be updated"
          : "It will be saved as a draft"
      }. Please contact your account manager or the support team for assistance to complete your order.`,
      "payment-error-1-msg",
      <div className='payment-err-actions'>
        <span
          onClick={() => {
            closeModal("payment-error-1-msg");
            // navigate("/console/orders");
          }}
        >
          Dismiss
        </span>
        <span
          onClick={() => {
            closeModal("payment-error-1-msg");
            navigate("/console/orders");
            window.Intercom(
              "showNewMessage",
              `Hello, I need help with completing my order, I was asked to contact my account manager ${
                assigneeName ? `, ${assigneeName}, ` : ""
              }for assistance`
            );
          }}
        >
          Contact Support
        </span>
      </div>,
      1200000000
    );
    closeConfirmOrderDrawer();
    return navigate("/console/orders");
  };

  const handleReturnOrderCreateErrTwo = async (email, assigneeName) => {
    setCreatingOrder(false);
    if (!state?.draftOrderNumber)
      await handleDraftOrderCreateApiCall(null, false); // reroute = false
    else if (state?.draftOrderNumber) await updateDraftOrder();
    renderErrorToast(
      "Action Required",
      "Please sign the customer agreement that was sent to your email address " +
        email +
        `. If you did not receive this email, please contact your account manager or support for assistance. ${
          state?.draftOrderNumber
            ? "Your draft will be updated"
            : "Your order will be saved as a draft"
        }.`,
      "payment-error-2-msg",
      <div className='payment-err-actions'>
        <span
          onClick={() => {
            closeModal("payment-error-2-msg");
            // navigate("/console/orders");
          }}
        >
          Dismiss
        </span>
        <span
          onClick={() => {
            closeModal("payment-error-2-msg");
            navigate("/console/orders");
            window.Intercom(
              "showNewMessage",
              `Hello, I am unable to see the customer agreement that was sent to my email. I was asked to contact my account manager ${
                assigneeName ? `, ${assigneeName}, ` : ""
              }for assistance`
            );
          }}
        >
          Contact Support
        </span>
      </div>,
      1200000000
    );
    closeConfirmOrderDrawer();
    return navigate("/console/orders");
  };

  const handleReturnOrderCreateErrThree = async () => {
    setCreatingOrder(false);
    if (!state?.draftOrderNumber)
      await handleDraftOrderCreateApiCall(null, false); // reroute = false
    else if (state?.draftOrderNumber) await updateDraftOrder();
    renderErrorToast(
      "Action Required",
      `Some information is missing in your onboarding. You need to provide this information before placing your order. ${
        state?.draftOrderNumber
          ? "Your draft will be updated"
          : "Your order will be saved as a draft"
      }.`,
      "payment-error-3-msg",
      <div className='payment-err-actions'>
        <span
          onClick={() => {
            closeModal("payment-error-3-msg");
            // navigate("/console/orders");
          }}
        >
          Dismiss
        </span>
        <span
          onClick={() => {
            navigate("/onboarding");
            closeModal("payment-error-3-msg");
          }}
        >
          Complete Onboarding
        </span>
      </div>,
      1200000000
    );
    closeConfirmOrderDrawer();
    return navigate("/console/orders");
  };

  const payWithBothMethods = async (
    outletToMakePayment,
    orderTotal,
    beforeOrder = false
  ) => {
    try {
      // const moneyToBeUsedFromWallet =
      //   orderTotal - Number(outletToMakePayment.account.currentBalance || 0);
      const moneyToBeUsedFromWallet = Number(
        outletToMakePayment.account.currentBalance || 0
      );
      console.log({ moneyToBeUsedFromWallet });
      const moneyToBeUsedFromCredit = orderTotal - moneyToBeUsedFromWallet;
      console.log({ moneyToBeUsedFromCredit });
      const customMonies = {
        creditBalance: moneyToBeUsedFromCredit,
        walletBalance: moneyToBeUsedFromWallet,
      };
      processPayment({
        paymentMethod: "",
        orderTotal,
        beforeOrder,
        customConfig: customMonies,
      });
    } catch (error) {
      throw error;
    }
  };

  const handlePaymentPlanWhenPaymentIsRequired = async (
    outletToMakePayment,
    orderTotal
  ) => {
    try {
      // wallet balance is account?.currentBalance
      if (
        outletToMakePayment?.account &&
        outletToMakePayment?.account?.currentBalance &&
        outletToMakePayment?.account?.currentBalance >= orderTotal
      )
        return await processPayment({
          paymentMethod: "wallet",
          orderTotal,
          beforeOrder: true,
        });
      // credit balance should be seen in the loanContract
      if (
        !outletToMakePayment?.account?.currentBalance &&
        outletToMakePayment?.loanContract &&
        outletToMakePayment?.loanContract?.creditBalance &&
        outletToMakePayment?.loanContract?.creditBalance >= orderTotal
      )
        return await processPayment({
          paymentMethod: "credit",
          orderTotal,
          beforeOrder: true,
        });
      // check for if there's balance in both and pay with both
      if (
        outletToMakePayment?.account?.currentBalance &&
        outletToMakePayment?.loanContract?.creditBalance &&
        Number(outletToMakePayment?.account?.currentBalance || 0) +
          Number(outletToMakePayment?.loanContract?.creditBalance || 0) >=
          orderTotal
      ) {
        console.log(
          "paying with wallet and credit balance....., when outlet requires payment"
        );
        await payWithBothMethods(outletToMakePayment, orderTotal, true); // pay before order = true
      } else
        return handleReturnOrderCreateErrOne(
          outletToMakePayment?.assignee?.assigneeName || ""
        );
    } catch (error) {
      throw error;
    }
  };

  const handlePaymentPlanWhenPaymentIsNotRequired = async (
    outletToMakePayment,
    orderTotal
  ) => {
    try {
      if (
        outletToMakePayment?.account &&
        outletToMakePayment?.account?.currentBalance &&
        outletToMakePayment?.account?.currentBalance >= orderTotal
      ) {
        // the user has a wallet balance that can pay for the order
        await processPayment({ paymentMethod: "wallet", orderTotal });
      } else if (
        !outletToMakePayment?.account?.currentBalance &&
        outletToMakePayment?.loanContract &&
        outletToMakePayment?.loanContract?.creditBalance &&
        outletToMakePayment?.loanContract?.creditBalance >= orderTotal
      ) {
        await processPayment({ paymentMethod: "credit", orderTotal });
      } else if (
        outletToMakePayment?.account?.currentBalance &&
        outletToMakePayment?.loanContract?.creditBalance &&
        Number(outletToMakePayment?.account?.currentBalance || 0) +
          Number(outletToMakePayment?.loanContract?.creditBalance || 0) >=
          orderTotal
      ) {
        console.log(
          "paying with wallet and credit balance....., when outlet does not requires payment"
        );
        payWithBothMethods(outletToMakePayment, orderTotal);
      } else if (outletToMakePayment?.loanContract) {
        // the user has a loan contract
        if (
          outletToMakePayment?.loanContract?.status !== "active" ||
          !outletToMakePayment?.loanContract?.creditBalance ||
          outletToMakePayment?.loanContract?.creditBalance < orderTotal
        )
          return handleReturnOrderCreateErrOne(
            outletToMakePayment?.assignee?.assigneeName || ""
          );
      } else {
        console.log({ outletToMakePayment });
        const isADocuSignRequiredOutlet = DOCU_SIGN_OUTLETS.includes(
          outletToMakePayment?.outletTypeId
        );
        console.log({ isADocuSignRequiredOutlet });
        if (
          isADocuSignRequiredOutlet &&
          outletToMakePayment?.documents?.termsAndCondition === "completed"
        ) {
          // has signed docu sign but without a loan contract for payment
          return handleReturnOrderCreateErrOne(
            outletToMakePayment?.assignee?.assigneeName || ""
          );
        }
        if (
          isADocuSignRequiredOutlet &&
          (outletToMakePayment?.documents?.termsAndCondition !== "completed" ||
            !outletToMakePayment?.documents)
        ) {
          // is a docu sign outlet but has not signed a loan contract
          return handleReturnOrderCreateErrTwo(
            outletToMakePayment?.email,
            outletToMakePayment?.assignee?.assigneeName
          );
        }
        if (
          !isADocuSignRequiredOutlet &&
          (!outletToMakePayment?.account?.accountNumber ||
            !outletToMakePayment?.company?.kyb?.rcNumber)
        ) {
          // is not a docu sign outlet but has not completed onboarding
          handleReturnOrderCreateErrThree();
        } else {
          // we dont know what the problem is
          console.log("we dont know what the problem is");
          return handleReturnOrderCreateErrOne(
            outletToMakePayment?.assignee?.assigneeName || ""
          );
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const handleOrderCreateStructure = async ({ isCreatingDraftOrder }) => {
    if (retailOutlet?.country === "GB") {
      const shippingRates = await handleGetShippingRates(preparedOrders);
      await handleCreateCartOrder(null, null, shippingRates.preparedOrders);
    }
    if (retailOutlet?.country === "NG")
      await handleNGUsersCreateCartOrder(preparedOrders);
    if (isCreatingDraftOrder) await handleDraftOrderCreateApiCall();
    else {
      // do payment checks ----
      let outletToMakePayment = locations.find(
        (el) => el._id === preparedOrders.retailOutletId
      );
      console.log({ outletToMakePayment });
      const orderTotal = (preparedOrders?.orders || []).reduce(
        (acc, curr) => acc + (curr?.total || 0),
        0
      );
      const {
        data: { data },
      } = await axios.get(
        `${process.env.REACT_APP_TD_APP_URL}/api/v4/procurement/get-retail-outlet?outletId=${outletToMakePayment._id}`,
        { headers: { "x-api-key": apiKey } }
      );
      outletToMakePayment = data;
      console.log("full data", outletToMakePayment);
      if (outletToMakePayment?.paymentRequired)
        handlePaymentPlanWhenPaymentIsRequired(outletToMakePayment, orderTotal);
      else
        handlePaymentPlanWhenPaymentIsNotRequired(
          outletToMakePayment,
          orderTotal
        );
    }
  };

  const closeModal = (id) => {
    toast.dismiss({ id });
  };

  const makePayment = async (body, config = { chargeBeforeOrder: false }) => {
    console.log({ body });
    try {
      const {
        data: { data },
      } = await axios.post(
        `${process.env.REACT_APP_AWS_API_URL}/charge-order`,
        body,
        {
          headers: {
            "x-api-key": apiKey,
            "Content-Type": "application/json",
          },
        }
      );
      renderErrorToast(
        "Successful",
        "Created your order successfully",
        "success-order-msg"
      );
      console.log({ data });
      navigate("/console/orders");
    } catch (error) {
      const { message, customCode } = errorHandler(error);
      console.log({ customCode });
      if (
        ([5008, 5003, 5010].includes(customCode) ||
          String(customCode).startsWith("50")) &&
        config.chargeBeforeOrder
      ) {
        // 5008 - insufficient credit, 5010 - insufficient wallet, 5003 - invalid amount passed
        return handleReturnOrderCreateErrOne(
          retailOutlet?.assignee?.assigneeName || ""
        );
      } else {
        // order is created but unpaid
        navigate("/console/orders");
        console.log({ message });
        renderErrorToast(
          "Successful",
          "Created your order successfully",
          "success-order-msg"
        );
      }

      setCreatingDraft(false);
      setCreatingOrder(false);
    }
  };

  const handleCreateDraftOrder = () => {
    setCreatingDraft(true);
    if (!state || !state?.draftOrderNumber) {
      // this is a new draft
      handleOrderCreateStructure({ isCreatingDraftOrder: true });
    }
    if (state && state?.draftOrderNumber) {
      const fulfillableOrders = preparedOrders.orders.filter(
        (el) => el.metadata.fulfillable
      );
      const items = fulfillableOrders.flatMap((el) => el.items);
      const newDraft = {
        ...state,
        items,
        notes: note,
      };
      return handleDraftOrderCreateApiCall(newDraft);
    }
  };

  const handleCreateOrder = () => {
    setCreatingOrder(true);
    handleOrderCreateStructure({ isCreatingDraftOrder: false });
  };

  const handleChangeProcessingProductValue = (
    processingProduct,
    value,
    autoRefresh = false
  ) => {
    if (Number(value) > processingProduct?.extvariants?.available)
      return renderErrorToast(
        "Available quantity exceeded",
        `Only ${processingProduct?.extvariants?.available} available in stock`,
        "qty-exceeded-err-1"
      );
    const productInReviewIndex = productsInReview.findIndex(
      (el) => el._id === processingProduct._id
    );
    productsInReview[productInReviewIndex] = {
      ...productsInReview[productInReviewIndex],
      count: Number(value),
    };
    setProductsInReview([...productsInReview]);
    if (autoRefresh) {
      const products = [...selectedProducts];
      productsInReview.forEach((product) => {
        const productsPos = products.findIndex((el) => el._id === product._id);
        products[productsPos] = product;
      });
      setSelectedProducts([...products]);
    }
  };

  const locations = useMemo(() => {
    const allBranches = [...(branches || [])];
    allBranches.push({
      outletBusinessName: retailOutlet.outletBusinessName,
      streetName:
        retailOutlet.formattedAddress || retailOutlet?.streetName || "-",
      _id: retailOutlet._id,
      account: retailOutlet.account,
    });

    return allBranches;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branches, retailOutlet]);

  const handleReviewOrder = async ({ calledByDraft = false }) => {
    if (productsInReview.length) {
      setProductsInReview([]);
      setShowProductSearchInput(true);
    }
    if (!branch.outletBusinessName)
      return renderErrorToast(
        "Cannot compute order",
        "You need to select an outlet before reviewing order",
        "review-order-err"
      );
    // 08039506802 - MRS OLAITAN OLAJIRE (OGUNYILEKA PROPERTIES IN BENJAMIN)
    if (!selectedProducts.length)
      return renderErrorToast(
        "Cannot compute order",
        "You need to add products before reviewing order",
        "review-order-err-1"
      );
    try {
      const doc = {};
      let unfulfillableOrders = [];
      const preparedOrders = await handlePrepareOrders();
      if (retailOutlet?.country === "NG") {
        const fulfillableOrders =
          preparedOrders?.orders?.filter((el) => el.metadata.fulfillable) || [];
        unfulfillableOrders =
          preparedOrders?.orders?.filter((el) => !el.metadata.fulfillable) ||
          [];
        doc.discount = fulfillableOrders.reduce(
          (acc, curr) => acc + curr?.discounts || 0,
          0
        );
      } else if (retailOutlet?.country === "GB") {
        const shippingRates = await handleGetShippingRates(preparedOrders);
        const { amount: shippingCost = 0, tax: shippingTax = 0 } =
          shippingRates?.ratesData?.shippingDetails?.standardCost;
        doc.shippingCost = shippingCost;
        const processingTax =
          shippingRates?.ratesData?.processingCost?.tax || 0;
        doc.processingCost =
          shippingRates?.ratesData?.processingCost?.amount || 0;
        const fulfillableOrders =
          preparedOrders?.orders?.filter((el) => el.metadata.fulfillable) || [];
        unfulfillableOrders =
          preparedOrders?.orders?.filter((el) => !el.metadata.fulfillable) ||
          [];
        doc.discount = fulfillableOrders.reduce(
          (acc, curr) => acc + curr?.discounts || 0,
          0
        );
        const promoTaxes = fulfillableOrders.reduce(
          (acc, curr) => acc + curr?.promoTaxes || 0,
          0
        );
        const saleTax = shippingTax + processingTax + promoTaxes;
        doc.tax = saleTax;
      }
      const unfulfillableItems = unfulfillableOrders
        .reduce((acc, curr) => {
          const items = curr.items.map((el) => ({
            ...el,
            errormessage: curr.metadata.errorMessage,
            errorTag: curr.metadata.error,
          }));
          return [...acc, ...items];
        }, [])
        .map((item) => {
          const selectedItemProductIndex = selectedProducts.findIndex(
            (product) => product.variantId === item.variantId
          );
          return {
            ...selectedProducts[selectedItemProductIndex],
            reviewing: true,
          };
        });
      setProductsInReview([...unfulfillableItems]);
      setReviewOrderData(doc);
      setCreatingDraft(false);
      setCreatingOrder(false);
      return document.getElementById("offcanvas-revieworder").click();
    } catch (error) {
      console.log("reviewing orders", error);
    }
  };

  const showPromotionValue = (promotion, currency) => {
    // const {
    //   rewards: [reward],
    // } = promotion;
    // if (reward.type === "percentage") return `${reward.value}%`;
    // return `${formatMoney(reward.value || 0)}`;
    return 0;
  };

  const enableReviewOrder = ({ showProductSearchInputParam = false }) => {
    setReviewOrderData(null);
    setProductsInReview([]);
    setShowProductSearchInput(showProductSearchInputParam);
  };

  return (
    <div
      className='tdp_create_orders'
      onClick={() => {
        setShowProductsDropdown(false);
        setShowBranches(false);
      }}
    >
      <div className='tdp_onboarding'>
        <div className='header'>
          <div className='cancel__'>
            <div onClick={() => navigate(-1) || navigate("/console/orders")}>
              <i className='bi bi-x-lg'></i>
            </div>
            <h4>Create Order</h4>
          </div>
          <div className='buttons_flex'>
            <button
              disabled={creatingDraft}
              onClick={() => processCreateOrder({ isCreatingDraftOrder: true })}
            >
              {creatingDraft ? (
                <Loader />
              ) : state?.draftOrderNumber ? (
                "Update Draft"
              ) : (
                "Save Draft"
              )}
            </button>
            <button
              disabled={creatingOrder}
              onClick={() =>
                processCreateOrder({ isCreatingDraftOrder: false })
              }
            >
              {creatingOrder ? <Loader /> : "Create Order"}
            </button>
          </div>
        </div>
      </div>
      <div className='body'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-6'>
              <div className='row'>
                <div className='col-2'></div>
                <div className='col-8 _preview-form'>
                  {isAParentOutlet() ? (
                    <>
                      <div className='head_text'>
                        <h3>Delivery location</h3>
                      </div>
                      {branch.edit ? (
                        <CustomSelect
                          placeholder='Select Location'
                          open={showBranches}
                          setOpen={(e) => {
                            e.stopPropagation();
                            setShowBranches(!showBranches);
                          }}
                          items={locations.map((location) => ({
                            ...location,
                            title: location.outletBusinessName,
                            subtext: location.streetName || "-",
                          }))}
                          action={(branch) => {
                            setBranch({
                              ...branch,
                              value: {
                                name: branch.outletBusinessName,
                                address: branch.streetName,
                              },
                              edit: false,
                            });
                            setShowBranches(false);
                          }}
                          selectedItem={{ ...branch }}
                        />
                      ) : (
                        <div className='_no-edit'>
                          <div className=''>
                            <h3>{branch.value.name}</h3>
                            <h5>{branch.value.address || "-"}</h5>
                          </div>
                          {isB2B ? (
                            <i
                              onClick={() => {
                                setBranch({ edit: true, value: {} });
                              }}
                            >
                              <EditSvg />
                            </i>
                          ) : null}
                        </div>
                      )}
                    </>
                  ) : null}
                  <div className='head_text'>
                    <h3>Add products</h3>
                    <h6
                      data-bs-toggle='offcanvas'
                      href='#offcanvasExample'
                      role='button'
                      aria-controls='offcanvasExample'
                      onClick={() => setShowProductsDropdown(false)}
                    >
                      See all products
                    </h6>
                  </div>
                  <div className='custom-select custom-input'>
                    {selectedProducts.map((product, key) => {
                      const productToEditIndex = productsInReview.findIndex(
                        (el) => el._id === product._id
                      );
                      const productToEdit =
                        productsInReview[productToEditIndex];
                      return productToEdit && productToEdit.key === key ? (
                        <ProcessingProduct
                          product={productToEdit}
                          key={key}
                          handleChangeProcessingProductValue={
                            handleChangeProcessingProductValue
                          }
                          removeProduct={() =>
                            handleRemoveProduct(key, product._id)
                          }
                          cancelProductProcessing={() =>
                            cancelProductProcessing(
                              productToEditIndex,
                              product._id
                            )
                          }
                          handleEditProduct={() => {
                            handleEditProduct(key);
                            enableReviewOrder({
                              showProductSearchInputParam: false,
                            });
                          }}
                          saveAndAddNew={() => {
                            handleEditProduct(key);
                            enableReviewOrder({
                              showProductSearchInputParam: true,
                            });
                          }}
                        />
                      ) : (
                        <div className='_product_row'>
                          <div className='product-name'>
                            <p>{product?.name}</p>
                            <span>x{product.count}</span>
                          </div>
                          <div className='price'>
                            <p>
                              {product?.currency?.symbol}
                              {formatMoney(
                                (product?.price || 1) * product.count
                              )}
                            </p>
                          </div>
                          <div className={"_actions"}>
                            <span
                              onClick={() => {
                                // if (reviewOrderData) return;
                                handleRemoveProduct(key, product._id);
                              }}
                            >
                              <DeleteSvg />
                            </span>
                            <span
                              onClick={() => {
                                selectProductForEdit(key);
                              }}
                            >
                              <EditSvg />
                            </span>
                          </div>
                        </div>
                      );
                    })}
                    {showProductSearchInput && !productsInReview.length ? (
                      <div className='product-input'>
                        <input
                          className='form-control'
                          placeholder='Find or add item'
                          onClick={(e) => e.stopPropagation()}
                          onChange={({ target: { value } }) =>
                            setSearchText(value.trim())
                          }
                        />
                        {fetchingProducts ? <Loader /> : null}
                      </div>
                    ) : productsInReview.length &&
                      productsInReview.filter((el) => !el.edit && !el.reviewing)
                        .length ? (
                      productsInReview.map((el, key) => {
                        return (
                          <ProcessingProduct
                            product={el}
                            handleChangeProcessingProductValue={
                              handleChangeProcessingProductValue
                            }
                            cancelProductProcessing={() =>
                              cancelProductProcessing(key, el._id)
                            }
                            handleEditProduct={saveProductProcessing}
                            key={key}
                          />
                        );
                      })
                    ) : !showProductSearchInput ? (
                      <p
                        className={"_add-item"}
                        onClick={() => setShowProductSearchInput(true)}
                      >
                        <i className='bi bi-plus-lg'></i>
                        Add another item
                      </p>
                    ) : null}
                    {showProductsDropdown ? (
                      <div className='_dropdown'>
                        {!variants.length ? (
                          <li>No products match your search</li>
                        ) : (
                          variants.map((el, key) => {
                            return (
                              <li
                                onClick={() => handleSelectDropdownProduct(el)}
                                key={key}
                              >
                                <div className=''>
                                  <p>{el.name}</p>
                                  {el?.extvariants ? (
                                    <p className='count'>
                                      {formatMoney(
                                        el?.extvariants?.available || 0
                                      )}{" "}
                                      items available
                                    </p>
                                  ) : null}
                                </div>
                                <p>
                                  {el.currency.symbol}
                                  {formatMoney(el.price)}
                                </p>
                              </li>
                            );
                          })
                        )}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className='products_error_container'>
                    <h3>Error Alert Message</h3>
                    <p>
                      Sentence detailing what the error is, shouldn’t exceed 2
                      lines in total.
                    </p>
                  </div> */}
                  <div className='head_text'>
                    <h3>Additional Details</h3>
                  </div>
                  <h5>
                    Enter an additional information you’d like the supplier to
                    be aware of
                  </h5>
                  <textarea
                    className='form-control'
                    placeholder='Note to supplier (optional)'
                    onChange={({ target: { value } }) => setNote(value)}
                    value={note}
                  />
                </div>
                <div className='col-2'></div>
              </div>
            </div>
            <div className='col-6 right__'>
              <PurchaseOrderDocument
                orderReference={state?.draftOrderNumber || orderReference}
                totalCalculation={totalCalculation}
                currency={currency}
                reviewOrderData={reviewOrderData}
                showPromotionValue={showPromotionValue}
                currencyCode={currencyCode}
                poOrders={poOrders}
                branch={branch}
              />
            </div>
          </div>
        </div>
      </div>
      <SideProducts
        syncProducts={handleSyncProducts}
        variants={outletVariants}
        fetchingProducts={fetchingProducts}
        selectedProducts={selectedProducts}
        collection={collectionProducts}
      />
      <AddPhoneNumberDrawer
        afterComplete={() => {
          processCreateOrder(
            action === DRAFT_ACTION
              ? {
                  isCreatingDraftOrder: true,
                  phoneNumberHasBeenAddedFromDrawer: true,
                }
              : {
                  isCreatingDraftOrder: false,
                  phoneNumberHasBeenAddedFromDrawer: true,
                }
          );
        }}
      />
      <ReviewOrderDrawer
        reviewOrderData={reviewOrderData}
        productsInReview={productsInReview}
        preparedOrders={preparedOrders}
        totalCalculation={totalCalculation}
        isDraft={action === DRAFT_ACTION}
        editingDraft={!!state?.draftOrderNumber}
        selectedProducts={selectedProducts}
        prepareOrder={(el) => handlePrepareOrders(el)}
        handleChangeProcessingProductValue={handleChangeProcessingProductValue}
        handleCreateDraftOrder={handleCreateDraftOrder}
        handleCreateOrder={handleCreateOrder}
        creatingDraft={creatingDraft}
        setCreatingDraft={setCreatingDraft}
        setCreatingOrder={setCreatingOrder}
        creatingOrder={creatingOrder}
        productValueChanged={reviewProductValueChanged}
        setProductValueChanged={setReviewProductValueChanged}
      />
    </div>
  );
}

function ProcessingProduct({
  product,
  handleChangeProcessingProductValue,
  cancelProductProcessing,
  handleEditProduct,
  removeProduct = null,
  key,
  saveAndAddNew = null,
}) {
  return (
    <div
      className='_processing-product'
      key={key}
      style={{ marginBottom: "12px" }}
    >
      <div className='details'>
        <input className='form-control' value={product?.name} disabled />
        <input
          className='form-control'
          value={Number(product?.count).toString()}
          type='number'
          disabled={
            product.errorTag === ITEMS_OUT_OF_STOCK && !product.available
          }
          min={1}
          onChange={({ target: { value } }) =>
            handleChangeProcessingProductValue(product, value)
          }
        />
        <input
          disabled
          className='form-control'
          value={product?.price * product?.count}
        />
      </div>
      {product?.extvariants ? (
        <p className='error-message max'>
          Max: {formatMoney(product?.extvariants?.available || 0)} items
        </p>
      ) : null}
      <div className='actions'>
        <button onClick={cancelProductProcessing}>Cancel</button>
        {product.errorTag === ITEMS_OUT_OF_STOCK && product.available < 1 ? (
          <button className='_remove' onClick={() => removeProduct(key)}>
            Remove
          </button>
        ) : product.errorTag === ITEMS_OUT_OF_STOCK && product.available > 0 ? (
          <>
            <button className='_remove' onClick={() => saveAndAddNew(key)}>
              Save and add new
            </button>
            <button onClick={() => handleEditProduct(key)}>Save</button>
          </>
        ) : (
          <button onClick={() => handleEditProduct(key)}>Save</button>
        )}
      </div>
    </div>
  );
}
